import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Result } from '../Models/Result';
import { AuthService } from './auth.service';
import { id } from 'date-fns/locale';
import { LeadsFilter } from '../Models/Leads';
import { User, Project } from '../Models/Users';
import { Services } from '../Models/Services';
import {
  AddUpdateTaskChecklist,
  Task,
  TaskComment,
  TaskStatus,
  TimeEntryReq,
  TimeSheetFilters,
} from '../Models/Task';
import { CostingFilters } from '../Models/TaskCost';
import { Role } from '../Models/Role';
import { AddPermissionsRequest } from '../Models/Permission';
import { Team } from '../Models/Teams';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authToken: string | null = null;

  constructor(private http: HttpClient, public authService: AuthService) {}

  login(user: any): Observable<any> {
    const url = `${environment.apiUrl}/User/Login`;
    // No need to manually stringify, just pass the object directly
    return this.http.post<Result<any>>(url, user);
  }

  // Example of a GET request with authentication headers

  getAllUsers(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllUsers`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  getAllLeads(): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAllLeads`;

    return this.http.get<Result<any>>(`${url}`, { withCredentials: true });
  }

  getLeadById(obj: LeadsFilter): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadById`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addUpdateLeads(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/AddUpdateLeads`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getDealStatus(projectId: number, statusTypeId: number): Observable<any> {
    const url = `${environment.apiUrl}/Deal/GetDealStatus`;

    return this.http.get<Result<any>>(`${url}/${projectId}/${statusTypeId}`, {
      withCredentials: true,
    });
  }
  getLeadStatus(projectId: number, statusTypeId: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetLeadStatus`;

    return this.http.get<Result<any>>(`${url}/${projectId}/${statusTypeId}`, {
      withCredentials: true,
    });
  }

  getRemarksByLeadId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetRemarksByLeadId`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getRemarksByDealId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Deal/GetRemarksByDealId`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  deleteLeadById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/DeleteLeadById`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }

  deleteLogById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/DeleteLogById`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }

  getAssigneeByLeadId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAssigneeByLeadId`;

    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }

  addUpdateRemarks(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/AddUpdateRemarks`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addUpdateDealLogs(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Deal/AddUpdateDealLogs`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addUpdateDeals(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Deal/AddUpdateDeals`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getLeads(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetAllLeads`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getMatchingLeadNames(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Leads/GetMatchingLeadNames`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getDeals(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Deal/GetAllDeals`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getEvents(filters: any): Observable<any> {
    const url = `${environment.apiUrl}/Events/GetEvents`;

    return this.http.post<Result<any>>(url, filters, { withCredentials: true });
  }

  insertUpdateEvent(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Events/InsertUpdateEvent`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  addFCMToken(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Home/AddFCMToken`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  deleteEvent(Id: number): Observable<any> {
    const url = `${environment.apiUrl}/Events/DeleteEvent`;

    return this.http.get<Result<any>>(`${url}/${Id}`, {
      withCredentials: true,
    });
  }
  getUserProjects(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetUserProjects`;

    return this.http.get<Result<any>>(`${url}`, { withCredentials: true });
  }

  getStatusByStatusTypeId(statusTypeId: number): Observable<any> {
    const url = `${environment.apiUrl}/Events/GetStatusByStatusTypeId`;

    return this.http.get<Result<any>>(`${url}/${statusTypeId}`, {
      withCredentials: true,
    });
  }

  //#region Common
  getCountries(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetCountries`;
    return this.http.get<Result>(`${url}`, { withCredentials: false });
  }
  getStates(countryId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetStates`;
    return this.http.get<Result>(`${url}/${countryId}`, {
      withCredentials: false,
    });
  }
  getCities(stateId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetCities`;
    return this.http.get<Result>(`${url}/${stateId}`, {
      withCredentials: false,
    });
  }
  getS3SignedUrl(sourceUrl: string, fileType: string): Observable<any> {
    const url = `${environment.apiUrl}/Common/GenerateS3SignedUrl`;

    return this.http.get<Result<any>>(`${url}/${sourceUrl}/${fileType}`, {
      withCredentials: true,
    });
  }
  getAwsCredentials(password: string): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetAwsCredentials`;
    return this.http.get<Result<any>>(`${url}/${password}`, {
      withCredentials: true,
    });
  }
  getS3SignedUrlForDocuments(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Common/GenerateS3SignedUrlForDocuments`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  setDefaultProject(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/SetDefaultProject`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  addOrUpdateProject(project: Project): Observable<any> {
    const url = `${environment.apiUrl}/Common/AddOrUpdateProject`;

    return this.http.post<Result<any>>(url, project, { withCredentials: true });
  }

  deleteProject(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/DeleteProject`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }

  uploadToS3(file: File, presignedUrl: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);

    const headers = new HttpHeaders({
      'Content-Type': file.type, // Example: Set Content-Type based on file type
    });

    const req = new HttpRequest('PUT', presignedUrl, formData, {
      headers: headers,
      reportProgress: true, // Enable progress tracking
    });

    return this.http.request(req);
  }

  //#endregion

  //#region Analytics

  getLeadsMetrics(obj: LeadsFilter): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetLeadsMetrics`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getLeadsConversions(obj: LeadsFilter): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetLeadsConversions`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getTopPerformers(projectId: number): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetTopPerformers`;

    return this.http.get<Result<any>>(`${url}/${projectId}`, {
      withCredentials: true,
    });
  }
  getTopPerformants(): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetTopPerformants`;

    return this.http.get<any>(url, { withCredentials: true });
  }
  getTasksConversions(): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetTasksConversions`;
    return this.http.get<any>(url, { withCredentials: true });
  }
  getTaskConversionsByClientId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetTaskConversionsByClientId`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }
  getTaskConversionsByCaseId(id: number, caseId: number): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetTaskConversionsByCaseId`;
    return this.http.get<any>(`${url}/${id}/${caseId}`, {
      withCredentials: true,
    });
  }
  getOverview(year: number): Observable<any> {
    const url = `${environment.apiUrl}/Analytics/GetOverview`;
    return this.http.get<any>(`${url}/${year}`, { withCredentials: true });
  }
  //#endregion

  //#region User Management

  addOrUpdateUser(user: User): Observable<any> {
    const url = `${environment.apiUrl}/User/AddOrUpdateUser`;

    return this.http.post<Result<any>>(url, user, { withCredentials: true });
  }

  deleteUser(userId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/DeleteUser`;

    return this.http.get<Result<any>>(`${url}/${userId}`, {
      withCredentials: true,
    });
  }

  getUserInvitationStatus(token: string): Observable<any> {
    const url = `${environment.apiUrl}/User/GetUserInvitationStatus`;

    return this.http.get<Result<any>>(`${url}/${token}`, {
      withCredentials: true,
    });
  }

  acceptInvitation(user: User): Observable<any> {
    const url = `${environment.apiUrl}/User/AcceptInvitation`;

    return this.http.post<Result<any>>(url, user, { withCredentials: true });
  }

  //#endregion

  //#region Client Information System
  getClientById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetClientById`;
    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getPerformanceByClientId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetPerformanceByClientId`;
    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getPerformanceByCaseId(id: number, caseId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetPerformanceByCaseId`;
    return this.http.get<Result<any>>(`${url}/${id}/${caseId}`, {
      withCredentials: true,
    });
  }
  getClients(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetAllClients`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getMatchingClientNames(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetMatchingClientNames`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  addUpdateClients(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/AddUpdateClient`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  deleteClientById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/DeleteClientById`;
    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  addUpdateClientdocuments(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/AddOrUpdateClientdocuments`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getAllClientDocuments(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetAllClientDocuments`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  deleteClientDocumentById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/DeleteClientDocumentById`;
    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getDocumentCountsByClientId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetDocumentCountsByClientId`;
    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getDocumentCountsByCaseId(clientId: number, caseId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetDocumentCountsByCaseId`;
    return this.http.get<Result<any>>(`${url}/${clientId}/${caseId}`, {
      withCredentials: true,
    });
  }
  getClientLocations(clientId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetClientLocations`;
    return this.http.get<Result<any>>(`${url}/${clientId}`, {
      withCredentials: true,
    });
  }
  addorUpdateClientLocations(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/AddorUpdateClientLocations`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  //#region Services
  deleteService(serviceId: number): Observable<any> {
    const url = `${environment.apiUrl}/Common/DeleteService`;
    return this.http.get<Result<any>>(`${url}/${serviceId}`, {
      withCredentials: true,
    });
  }
  getServices(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetServices`;
    return this.http.get<Result<any>>(`${url}`, { withCredentials: true });
  }
  addOrUpdateService(service: Services): Observable<any> {
    const url = `${environment.apiUrl}/Common/AddOrUpdateService`;
    return this.http.post<Result<any>>(url, service, { withCredentials: true });
  }
  //#endregion

  //#region Services Category
  getServicesCategory(): Observable<any> {
    const url = `${environment.apiUrl}/Common/GetServicesCategory`;
    return this.http.get<Result>(`${url}`, { withCredentials: false });
  }
  //#endregion

  //#region Case Services
  addOrUpdateCase(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/AddOrUpdateCase`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getCaseById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetCaseById`;
    return this.http.get<Result<any>>(`${url}/${id}`, {
      withCredentials: true,
    });
  }
  getCases(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetAllCases`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getCurrentCase(clientId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetCurrentCase`;
    return this.http.get<Result<any>>(`${url}/${clientId}`, {
      withCredentials: true,
    });
  }
  getBillingType(): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetBillingType`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }
  getLastBudgetSetting(clientId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetLastBudgetSetting`;
    return this.http.get<Result<any>>(`${url}/${clientId}`, {
      withCredentials: true,
    });
  }
  getBillableType(caseId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetBillableType`;
    return this.http.get<Result<any>>(`${url}/${caseId}`, {
      withCredentials: true,
    });
  }
  addUpdateCaseBillingSettings(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/AddOrUpdateCaseBillingSettings`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  addUpdateCaseAssigneRates(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Client/AddOrUpdateCaseAssigneRates`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  checkCaseUserTaskMapping(caseId: number, userIds: string): Observable<any> {
    const url = `${environment.apiUrl}/Client/CheckCaseUserTaskMapping`;
    return this.http.get<Result<any>>(`${url}/${caseId}/${userIds}`, {
      withCredentials: true,
    });
  }
  getCaseAssignees(caseId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetCaseAssignees`;
    return this.http.get<Result<any>>(`${url}/${caseId}`, {
      withCredentials: true,
    });
  }
  updateCaseStatus(Id: number, Active: boolean): Observable<any> {
    const url = `${environment.apiUrl}/Client/UpdateCaseStatus`;
    return this.http.get<Result<any>>(`${url}/${Id}/${Active}`, {
      withCredentials: true,
    });
  }
  getClientCaseTotals(caseId: number): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetClientCaseTotals`;
    return this.http.get<any>(`${url}/${caseId}`, { withCredentials: true });
  }
  //#endregion
  getDocumentcategory(): Observable<any> {
    const url = `${environment.apiUrl}/Client/GetAllDocumentCategories`;
    return this.http.get<Result>(`${url}`, { withCredentials: false });
  }

  //#region Task Endpoints
  getAllTasks(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTasks`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getAllTaskStatuses(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskStatuses`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskStatusById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskStatusById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }
  updateTaskStatus(obj: TaskStatus): Observable<any> {
    const url = `${environment.apiUrl}/Task/UpdateTaskStatus`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getTaskOverview(month: number, year: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskOverview`;
    return this.http.get<any>(`${url}/${month}/${year}`, {
      withCredentials: true,
    });
  }
  // Tasks Endpoints
  GetRecentTasksByUserId(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetRecentTasksByUserId`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }

  // TaskUserMapping Endpoints
  getAllTaskUserMappings(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskUserMappings`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskUserMappingById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskUserMappingById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }

  // TaskActivities Endpoints
  getAllTaskActivities(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskActivities`;
    return this.http.get<any>(url, { withCredentials: true });
  }
  getTaskActivitiesByTaskId(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskActivities`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }
  GetAllRecentTaskActivities(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllRecentTaskActivities`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getTaskActivityById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskActivityById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }

  // TaskDocuments Endpoints
  getAllTaskDocuments(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskDocuments`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskDocumentById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskDocumentById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }
  addOrUpdateTaskDocs(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddOrUpdateTaskDocs`;

    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  // TaskComments Endpoints
  getAllTaskComments(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskComments`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskCommentById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskCommentById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }

  // TaskChecklists Endpoints
  getAllTaskChecklists(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskChecklists`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskChecklistById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskChecklistById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }

  addOrUpdateCheckList(obj: AddUpdateTaskChecklist): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddOrUpdateCheckList`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  // TaskTags Endpoints
  getAllTaskTags(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTaskTags`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskTagById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskTagById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }
  getTags(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTags`;
    return this.http.get<any>(url, { withCredentials: true });
  }
  getPriorityLevels(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetPriorityLevels`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTaskDashboardData(TaskStatus: TaskStatus): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskDashboardData`;
    return this.http.post<Result<any>>(url, TaskStatus, {
      withCredentials: true,
    });
  }

  addOrUpdateTask(object: Task): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddOrUpdateTask`;
    return this.http.post<Result<any>>(url, object, { withCredentials: true });
  }
  getMonthlyTaskDurationByUserId(filters: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetMonthlyTaskDurationByUserId`;

    return this.http.post<Result<any>>(url, filters, { withCredentials: true });
  }
  deleteTask(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/DeleteTask`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }
  getSystemTemplates(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetSystemTemplates`;
    return this.http.get<any>(`${url}`, { withCredentials: true });
  }
  addBulkTasks(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddBulkTasks`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  addOrUpdateSystemTemplates(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddOrUpdateSystemTemplates`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  addTasksTemplate(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddTasksTemplate`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  addSingleTaskToTemplate(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddSingleTaskToTemplate`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  updateTasksTemplate(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Task/UpdateTasksTemplate`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  //#endregion
  //#region TimeEntries Endpoints
  getAllTimeEntries(): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetAllTimeEntries`;
    return this.http.get<any>(url, { withCredentials: true });
  }

  getTimeEntryById(id: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTimeEntryById`;
    return this.http.get<any>(`${url}/${id}`, { withCredentials: true });
  }

  startTask(obj: TimeEntryReq): Observable<any> {
    const url = `${environment.apiUrl}/Task/StartTask`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  stopTask(obj: TimeEntryReq): Observable<any> {
    const url = `${environment.apiUrl}/Task/StopTask`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getTaskTimeTracking(obj: TimeEntryReq): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTaskTimeTracking`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getCurrentRunningTask(taskId: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetCurrentRunningTaskByUserId`;
    return this.http.get<any>(`${url}/${taskId}`, { withCredentials: true });
  }
  getCommentsByTaskId(taskId: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetCommentsByTaskId`;
    return this.http.get<any>(`${url}/${taskId}`, { withCredentials: true });
  }
  addOrUpdateTaskComment(obj: TaskComment): Observable<any> {
    const url = `${environment.apiUrl}/Task/AddOrUpdateTaskComment`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getTimeTrackingDetailsByTaskId(taskId: number): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTimeTrackingDetailsByTaskId`;
    return this.http.get<any>(`${url}/${taskId}`, { withCredentials: true });
  }
  getTimeSheetData(obj: TimeSheetFilters): Observable<any> {
    const url = `${environment.apiUrl}/Task/GetTimeSheetData`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getCostingData(obj: CostingFilters): Observable<any> {
    const url = `${environment.apiUrl}/Costing/GetCostingData`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  getCostingBreakout(taskId: number): Observable<any> {
    const url = `${environment.apiUrl}/Costing/GetCostingBreakout`;
    return this.http.get<any>(`${url}/${taskId}`, { withCredentials: true });
  }
  //#endregion

  //#region Costing
  getOrganizationTotals(year: number): Observable<any> {
    const url = `${environment.apiUrl}/Costing/GetOrganizationTotals`;
    return this.http.get<any>(`${url}/${year}`, { withCredentials: true });
  }

  getCostAndHoursMonthlyTrend(year: number): Observable<any> {
    const url = `${environment.apiUrl}/Costing/GetCostAndHoursMonthlyTrend`;
    return this.http.get<any>(`${url}/${year}`, { withCredentials: true });
  }
  getCaseStatisticsByOrgId(count: number, year: number): Observable<any> {
    const url = `${environment.apiUrl}/Costing/GetCaseStatisticsByOrgId`;
    return this.http.get<any>(`${url}/${count}/${year}`, {
      withCredentials: true,
    });
  }
  getCostingDataForExcel(filters: CostingFilters): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${environment.apiUrl}/Costing/GetCostingDataForExcel`, filters, {
      headers,
      responseType: 'blob' as 'json',
      withCredentials: true
    });
  }
  getCaseStatisticsForExcelByOrgId(count: number, year: number): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${environment.apiUrl}/Costing/GetCaseStatisticsForExcelByOrgId/${count}/${year}`,  {
      headers,
      responseType: 'blob' as 'json',
      withCredentials: true
    });
  }
  //#endregion

  //#region Time Tracking

  getTimeTrackingOverview(month: number, year: number): Observable<any> {
    const url = `${environment.apiUrl}/TimeTracking/GetTimeTrackingOverview`;
    return this.http.get<any>(`${url}/${month}/${year}`, {
      withCredentials: true,
    });
  }

  getUsersReport(month: number, year: number, userIds: string): Observable<any> {
    const url = `${environment.apiUrl}/TimeTracking/GetUsersReport`;
    return this.http.get<any>(`${url}/${month}/${year}/${userIds}`, {
      withCredentials: true,
    });
  }
  //#endregion

  //#region Plugins

  activateHrmsPlugin(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/ActivateHrmsPlugin`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  saveHrmsPluginSettings(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/SaveHrmsPluginSettings`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }

  getHrmsLocations(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetHrmsLocations`;
    return this.http.get<any>(`${url}`, { withCredentials: true });
  }

  getHrmsEmployees(locationId: number): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetHrmsEmployees`;
    return this.http.get<any>(`${url}/${locationId}`, {
      withCredentials: true,
    });
  }

  getHrmsPluginConfig(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetHrmsPluginConfig`;
    return this.http.get<any>(`${url}`, { withCredentials: true });
  }

  deletePluginConfig(pluginId: number): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/DeletePluginConfig`;
    return this.http.get<any>(`${url}/${pluginId}`, {
      withCredentials: true,
    });
  }

  getImportedHrmsEmployeesCount(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/GetImportedHrmsEmployeesCount`;
    return this.http.get<any>(`${url}`, {
      withCredentials: true,
    });
  }

  syncHrmsEmployees(): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/SyncHrmsEmployees`;
    return this.http.get<any>(`${url}`, {
      withCredentials: true,
    });
  }
  markAttendanceByHrmsPlugin(obj: any): Observable<any> {
    const url = `${environment.apiUrl}/Plugin/MarkAttendanceByHrmsPlugin`;
    return this.http.post<Result<any>>(url, obj, { withCredentials: true });
  }
  //#endregion
  //#region Roles and permissions
  getAllRoles(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllRoles`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  addOrUpdateRole(role: Role): Observable<any> {
    const url = `${environment.apiUrl}/User/AddOrUpdateRole`;
    return this.http.post<Result<any>>(url, role, { withCredentials: true });
  }

  deleteRole(roleId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/DeleteRole`;
    return this.http.get<Result<any>>(`${url}/${roleId}`, {
      withCredentials: true,
    });
  }

  getPermissions(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetPermissions`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  addPermissions(
    addPermissionsRequest: AddPermissionsRequest
  ): Observable<any> {
    const url = `${environment.apiUrl}/User/AddPermissions`;
    return this.http.post<Result<any>>(url, addPermissionsRequest, {
      withCredentials: true,
    });
  }

  getUserRoleByUserId(userId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetUserRoleByUserId`;

    return this.http.get<Result<any>>(`${url}/${userId}`, {
      withCredentials: true,
    });
  }

  //#endregion

 //#region Job Titles

 getJobTitles(): Observable<any> {
  const url = `${environment.apiUrl}/User/GetAllJobTitles`;
  return this.http.get<Result<any>>(`${url}`, {
    withCredentials: true,
  });
}

 //#endregion

  //#region Teams

  getAllTeams(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAllTeams`;
    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  getTeamById(teamId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetTeamById`;
    return this.http.get<Result<any>>(`${url}/${teamId}`, {
      withCredentials: true,
    });
  }

  addUpdateTeam(team: Team): Observable<any> {
    const url = `${environment.apiUrl}/User/${
      team?.id > 0 ? 'UpdateTeam' : 'AddTeam'
    }`;
    return this.http.post<Result<any>>(url, team, {
      withCredentials: true,
    });
  }

  updateTeam(team: Team): Observable<any> {
    const url = `${environment.apiUrl}/User/UpdateTeam`;
    return this.http.post<Result<any>>(url, team, {
      withCredentials: true,
    });
  }

  deleteTeam(teamId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/DeleteTeam`;
    return this.http.get<Result<any>>(`${url}/${teamId}`, {
      withCredentials: true,
    });
  }

  getAvailableUsersForTeam(): Observable<any> {
    const url = `${environment.apiUrl}/User/GetAvailableUsersForTeam`;

    return this.http.get<Result<any>>(`${url}`, {
      withCredentials: true,
    });
  }

  getUsersByTeamId(teamId: number): Observable<any> {
    const url = `${environment.apiUrl}/User/GetUsersByTeamId`;

    return this.http.get<Result<any>>(`${url}/${teamId}`, {
      withCredentials: true,
    });
  }

  //#endregion
}
