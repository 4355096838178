<div class="container">

    <div class="page-head-container">
      <h4 class="page-title"><i class="fa-regular fa-dashboard"></i> Team Report</h4>
      <!-- Filter Section -->
      <div class="d-flex align-items-center">
        <div class="page-head-container-label">Month:</div>
        <p-calendar [(ngModel)]="selectedDate" view="month" dateFormat="MM, yy" [readonlyInput]="true" [showIcon]="true"
          [style.width]="'170px'" />
          <div class="page-head-container-label">Team:</div>
          <p-dropdown appendTo="body" name="name" [options]="filteredTeams" [(ngModel)]="selectedTeams" optionLabel="name"
            placeholder="Select User Name"  [filter]="true" filterPlaceholder="Search Users" (onChange)="onUserSelect($event)"></p-dropdown>
          <button id="apply-filter" class="btn btn-sm btn-outline-dark action-button-outline ml-1"
            (click)="applyFilter()"><i class="fa-solid fa-check"></i> {{ "Apply" | translate }}</button>
      </div>
    </div>
    <div class="alert alert-warning p-1 mt-3 mb-1 fs-12px" role="alert" *ngIf="selectedTeams && selectedTeams.id === 0">
      <i class="fa fa-regular fa-circle-info text-dark mr-1 ml-1"></i>
      <span>Please select the team to build the report.
      </span>
    </div>
    <div class="row">
      <!-- Summary Section -->
      <div class="col-md-7">
        <div class="card mb-3" style="height: 265px;">
          <div class="card-body">
            <h5 class="card-title card-heading">Overall  Working Hours Summary</h5>
            <div class="table-responsive" 
            *ngIf="overview.timeTrackingList && overview.timeTrackingList.length > 0; else  noData;">
            <table class="table table-hover">
              <thead>
                <tr class="sticky-header">
                  <th> #</th>
                  <th>{{"Name" | translate}}</th>
                  <th>{{"Total Hrs." | translate}}</th>
                  <th>{{"Billable Hrs." | translate}}</th>
                  <th>{{"Non-Billable Hrs." | translate}}</th>
                  <th>{{"Last Week Hrs." | translate}}</th>
                  <th>{{"Current Week Hrs." | translate}}</th>
                  <th>{{"Avg. Hrs Per Task" | translate}}</th>
                  <th>{{"Total Cost" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of overview.timeTrackingList;let rowIndex = index">
                  <td style="vertical-align: sub;">
                    <div class="">{{ rowIndex + 1}}</div>
                  </td>
                  <td class="taskname-td">
                    <div class="d-flex align-items-center taskname-container">
                      <div class="taskname m-0">
                        {{ (item.name )  }}
                    
                       
                       
                      </div>
                    </div>
                  </td>
                  <td>
                    {{ ((item.totalHours || 0) | number:'1.2-2') + ' H' }}
                  </td>
                  <td>
                    <div class="ellipsis" style="font-weight: 500; text-transform: capitalize;">
                      <p class="mb-1">{{ ((item.billableHours || 0) | number:'1.2-2') + ' H' }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="ellipsis">
                      <p class="fs-11px mb-0">{{ ((item.nonBillableHours || 0) | number:'1.2-2') + ' H' }}</p>
                    </div>
                  </td>
                  <td>
                    <p class="fs-11px mb-0">{{ ((item.lastWeekHours || 0) | number:'1.2-2') + ' H' }}</p>
                  </td>
                  <td>
                    <div>{{ ((item.currentWeekHours || 0) | number:'1.2-2') + ' H' }}</div>
                  </td>
                  <td>
                    <div class="ellipsis" style="font-weight: 500; text-transform: capitalize;">
                      <p class="mb-0">{{ ((item.averageHoursPerTask || 0) | number:'1.2-2') + ' H' }}</p>
                    </div>
                  </td>
                  <td>
                    <div class="ellipsis" style="max-width: 85px; font-weight: 500; text-transform: capitalize;">
                      <p class="mb-0">{{ ((item.totalCost || 0)  | currency: 'Rs ') }}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            <!-- <ul class="list-group" *ngIf="overview.timeTrackingList && overview.timeTrackingList.length > 0; else  noData;">
              <li class="list-group-item">Total Hours: {{ (overview.timeTrackingList[0].totalHours | number:'1.2-2') + ' H' }}</li>
              <li class="list-group-item">Billable Hours: {{ (overview.timeTrackingList[0].billableHours | number:'1.2-2') + ' H' }}</li>
              <li class="list-group-item">Non-Billable Hours: {{ (overview.timeTrackingList[0].nonBillableHours | number:'1.2-2') + ' H' }}
              </li>
              <li class="list-group-item">Last Week Hours: {{ (overview.timeTrackingList[0].lastWeekHours | number:'1.2-2') + ' H' }}</li>
              <li class="list-group-item">Current Week Hours: {{ (overview.timeTrackingList[0].currentWeekHours | number:'1.2-2') + ' H' }}
              </li>
              <li class="list-group-item">Average Hours Per Task: {{ (overview.timeTrackingList[0].averageHoursPerTask |number:'1.2-2') + ' H'
                }}</li>
               
                
            </ul> -->
            <ng-template #noData>
              <div class="no-track">
                <h5>No work done in this month</h5>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-md-5">
        <div class="card" style="height: 385px;">
          <div class="body">
           <h5 class="card-title card-heading">Recent Tasks</h5>
          </div>
            <div class="table-responsive" 
            *ngIf="overview.taskList != null && overview.taskList.length > 0; else  noTaskData;">
            <table class="table table-hover">
              <thead>
                <tr class="sticky-header">
                  <th style="width: 20px; padding: 0.75rem;"> #</th>
                  <th style="width: 300px;">{{"Task" | translate}}</th>
                  <th>{{"Client Name" | translate}}</th>
                  <th>{{"Status" | translate}}</th>
                  <th>{{"Priority" | translate}}</th>
                  <th>{{"Due Date" | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of overview.taskList;let rowIndex = index">
                  <td style="vertical-align: sub;">
                    <div class="">{{ rowIndex + 1}}</div>
                  </td>
                  <td class="taskname-td">
                    <div class="d-flex align-items-center taskname-container">
                      <div class="taskname m-0">
                        {{ padZero(item.id,3) + ' - ' + item.title }}
                        <span
                        class="badge status {{ item.isStarted ? 'completed' : 'overdue' }} bg">{{ item.isStarted ? 'Running' : 'Stopped' }}</span>
                        <p class="task-title-date m-0">Created At: {{ item.dateCreated | date }}</p>
                       
                      </div>
                    </div>
                  </td>
      
                  <td>
                    <div class="ellipsis" style="max-width: 200px;font-weight: 500; text-transform: capitalize;">
                      <p class="mb-1">{{ !item.clientName ? '-':item.clientName |translate }}</p>
                    </div>
                    <div class="ellipsis" style="color: #7d7b7b;text-transform: capitalize;background: #f7f7f7;">
                      <p class="fs-11px mb-0">{{ !item.caseName ? '-' : item.caseName | translate }}</p>
                    </div>
                  </td>
                  <td>
                    <p class="status bg border badge rounded-pill mb-1" [ngClass]="getStatusClass(item)">
                      <span>{{ item.status | translate }}</span>
                    </p>
                    <p class="fs-11px mb-0">Time Spent: <strong>{{ item.taskStatusId > 1 ? item.actualHours : 0 }} H</strong>
                    </p>
                  </td>
                  <td>
                    <div class="badge rounded-pill" [style.color]="item.priorityForeColor"
                      [style.backgroundColor]="item.priorityBgColor">{{ item.priorityName | translate }}</div>
                  </td>
      
                  <!-- <td>
                  <div class="ellipsis" style="max-width: 130px; font-weight: 500">{{ !item.description ? '-' :item.description | translate }}</div>
                </td> -->
      
                  <td>
                    <div class="ellipsis" style="max-width: 85px;font-weight: 500; text-transform: capitalize;">
                      <p class="mb-0">{{ item.dueDate | date }}</p>
                    </div>
                    <span [ngSwitch]="getCompletionStatus(item)">
                      <span *ngSwitchCase="'Overdue'" class="badge status overdue bg">Overdue</span>
                      <span *ngSwitchCase="'On Track'" class="badge status on-track bg">On Track</span>
                      <span *ngSwitchCase="'Completed Early'" class="badge status completed bg">Completed Early</span>
                    </span>
                  </td>
                 
                </tr>
              </tbody>
            </table>
          </div>
            <ng-template #noTaskData>
              <div class="no-track">
                <h5>No work done on tasks</h5>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
       <!-- Monthly Actual vs Estimated Cost Chart -->
       <div class="col-md-2">
        <div class="card mb-3" style="height: 265px;">
          <div class="card-body">
            <h5 class="card-title card-heading">Overall  Clients Summary</h5>
            <ul class="list-group" *ngIf="overview.timeTrackingList && overview.timeTrackingList.length > 0; else  noData;">
             
                 <li class="list-group-item">Total Clients: {{ (overview.clientSummary.totalClients | number:'1.2-2')  }}
                </li>
                 <li class="list-group-item">Total Cases: {{ (overview.clientSummary.totalCases | number:'1.2-2')  }}
                </li>
                <li class="list-group-item">Total Tasks: {{ (overview.clientSummary.totalTasks | number:'1.2-2')  }}
                </li>
                <li class="list-group-item">Completed Tasks: {{ (overview.clientSummary.completedTasks | number:'1.2-2')  }}
                </li>
                
            </ul>
            <ng-template #noData>
              <div class="no-track">
                <h5>No work done in this month</h5>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    <div class="col-md-10">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Cost Trend Over Time</h5>
          <p-chart type="line" [data]="monthlyActualvsEstimatedCostData" [options]="chartOptionsCost"
            height="200"></p-chart>
        </div>
      </div>
    </div>
</div>
