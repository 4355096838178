import { Task } from "./Task";

export class UserReport {
    summary: CalendarSummary[];
    clientSummary: ClientSummary;
    timeTrackingList: TimeTrackingList[];
    taskList: Task[];
}

export class CalendarSummary {
    yearMonth: string;
    monthNumber: number;
    monthName: string;
    totalCost: number;
    totalHours: number;
}

export class TimeTrackingList {
    userId: number;
    name: string;
    totalHours: number;
    billableHours: number;
    nonBillableHours: number;
    lastWeekHours: number;
    currentWeekHours: number;
    averageHoursPerTask: number;
    totalCost: number;
}

export class ClientSummary {
    totalClients: number;
    totalCases: number;
    totalTasks: number;
    completedTasks: number;
}