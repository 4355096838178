import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Editor, Toolbar, Validators, toDoc, toHTML } from 'ngx-editor';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { FileSelectEvent, FileUploadEvent } from 'primeng/fileupload';
import {
  AddUpdateTaskChecklist,
  PriorityLevels,
  Task,
  TaskCheckInState,
  TaskComment,
  TaskStatus,
  TasksFilter,
  TimeEntry,
  TimeEntryReq,
} from 'src/app/Models/Task';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import {
  calculateTotalHours,
  formatBytes,
  padZero,
  parseJSONToLowerCaseFirstChar,
} from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';

import schema from 'src/app/schemas/schema';
import { mentionPlugin } from 'src/app/plugins/MentionsPlugin';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, map, catchError, of } from 'rxjs';
import { Tags } from 'src/app/Models/TaskTag';
import { ClientsFilter, Clients } from 'src/app/Models/Clients';
// import { ClientCase, CaseService } from 'src/app/Models/Case';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { TaskActivities } from 'src/app/Models/TaskActivities';
import { ClientCase, CaseService } from 'src/app/Models/Case';
import { ConfirmationService } from 'primeng/api';
import { HrmsPluginSettings } from 'src/app/Models/HrmsPluginSettings';
import { CheckInRequestDetail } from 'src/app/Models/CheckInRequestDetail';
import { AttendanceConfirmationComponent } from '../../common/attendance-confirmation/attendance-confirmation.component';
import { FileUpload } from 'primeng/fileupload';
import { AddOrUpdateTaskDocs, TaskDocument } from 'src/app/Models/TaskDocument';
import { AWSServerCredentials } from 'src/app/Models/AWSServerCredentials';
import { S3 } from 'aws-sdk';
import { stringify } from 'querystring';
declare var $: any;
@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.css'],
})
export class TaskViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fileUploadInput') fileUpload: FileUpload;
  @Output() onModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(AttendanceConfirmationComponent)
  attendanceConfirmationModal: AttendanceConfirmationComponent;
  taskCheckIn:TaskCheckInState;
  currentUserId: number = 0;
  uploadedFiles: any[] = [];
  taskDocs: AddOrUpdateTaskDocs;
  credentials: AWSServerCredentials = new AWSServerCredentials();
  isConversationStarted: boolean = false;
  comments: TaskComment[] = [
    {
      taskId: 1,
      commenterName: 'Saqib Lodhi',
      commenterPicture:
        'https://miro.medium.com/v2/resize:fill:88:88/0*Bru5JtBnh5fNUdhP.',
      commentText:
        'Custom content for an option is displayed with the pTemplate property that takes an option as a parameter.',
    },
    {
      taskId: 2,
      commenterName: 'Salman Jamil',
      commenterPicture:
        'https://avatars.githubusercontent.com/u/16211801?s=96&v=4',
      commentText:
        'VirtualScrolling is an efficient way of rendering the options by displaying a small subset of data in the viewport at any time. When dealing with huge number of options, it is suggested to enable VirtualScrolling to avoid performance issues. Usage is simple as setting virtualScroll property to true and defining virtualScrollItemSize to specify the height of an item',
    },
  ];
  editorDetails: Editor;
  editorDetailsMenu = false;

  editorAcceptance: Editor;
  editorAcceptanceMenu = false;

  editorComment: Editor;
  editorCommentMenu = true;

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  colorPresets = ['red', '#FF0000', 'rgb(255, 0, 0)'];
  htmlDetails =
    '<p><strong>Lorem Ipsum</strong>&nbsp;is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>';

  htmlAcceptance = '';
  checkList: AddUpdateTaskChecklist = new AddUpdateTaskChecklist();
  markAttendanceDetails: CheckInRequestDetail;
  comment: TaskComment = new TaskComment();
  taskActivities: TaskActivities[] = [];
  taskFilter: TasksFilter = new TasksFilter();
  currentPage = 1;
  pageSize = 50;
  taskStatus: TaskStatus = new TaskStatus();
  selectedcheckLists: boolean = false;
  selectedClient: any;
  selectedStatus: any;
  selectedTags: any[];
  startDate: Date;
  dueDate: Date;
  isDisabled: any = true;
  taskPriorties: PriorityLevels[] = [];
  tags: Tags[] = [];
  assignees: User[] = [];
  case: ClientCase[] = [];
  services: CaseService[] = [];
  progress = 0;
  isUploading: boolean = false;
  selectedCase: any;
  isDataSaving: boolean;
  taskId: number;
  timeEntry: TimeEntry = undefined;
  currentTask: Task;
  task: Task = new Task();
  selectedAssignees: User[];
  taskStatuses: TaskStatus[] = [];
  jsonDoc: any;
  jsonDescriptionDoc: any;
  jsonAcceptanceDoc: any;
  timeEntries: TimeEntry[] = [];
  totalHours: string = '0';
  isDocUploading: boolean = false;
 //#region HRMS Plugin

  hrmsPluginSettings: HrmsPluginSettings;

  //#endregion
  constructor(
    private authService: AuthService,
    private config: PrimeNGConfig,
    private messageService: MessageService,
    private apiService: ApiService,
    private dialogConfig: DynamicDialogConfig,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private dialogRef: DynamicDialogRef
  ) {
    this.currentUserId = this.authService.currentUserValue.id;
    this.task = this.dialogConfig.data.task;

    this.taskId = this.task?.id > 0 ? this.task.id : 0;
    if (
      Object.keys(this.task?.checklists).length === 0 ||
      this.task?.checklists.length === 0
    ) {
      this.task.checklists = [];
    }
    this.loadHrmsPluginConfig();
    this.loadContent();
  }

  loadContent(): void {
    this.getTaskStatus();
    this.getPriorityLevels();
    this.getTags().then((resp) => {
      if (this.task.id > 0 && this.task.tagIds?.length > 0) {
        this.selectedTags = this.tags.filter((x) =>
          this.task.tagIds.includes(x.id)
        );
      }
    });
    this.getCaseAssignees(this.task.caseId).then((resp) => {
      if (this.task.id > 0 && this.task.assigneeIds.length > 0) {
        this.selectedAssignees = this.assignees.filter((assignee) => {
          const matchingTaskAssignee = this.task.taskAssignee.find(
            (taskAssignee) => taskAssignee.Id === assignee.id
          );
          if (matchingTaskAssignee) {
            assignee.estimatedHours =
              matchingTaskAssignee.EstimatedHours || assignee.estimatedHours;
            assignee.ratesPerHour =
              matchingTaskAssignee.RatesPerHour || assignee.ratesPerHour;
            assignee.selected = true;
          }
          return !!matchingTaskAssignee;
        });
      }
    });
    if (this.task.id > 0) {
      this.getClientById(this.task.clientId).then((resp) => {
        this.getCurrentCase(this.task.clientId).then((resp) => {
          const serviceJson = this.case
            .filter((x) => x.id === this.task.caseId)
            .map((x) => x.servicesJson)[0];
          this.services = parseJSONToLowerCaseFirstChar(serviceJson);
        });
      });
      this.task.checklists = parseJSONToLowerCaseFirstChar(
        this.task.checkListJSON
      );
      if(this.task.documentsJSON !== null){
        this.task.documents = parseJSONToLowerCaseFirstChar(
          this.task.documentsJSON
        );
      }
     else{
      this.task.documents =[];
     }
      this.jsonDescriptionDoc = toDoc(this.task?.description);
      this.jsonAcceptanceDoc = toDoc(this.task?.acceptanceCriteria);
      this.getCommentsByTaskId(this.task.id);
      this.getTaskActivitiesByTaskId(this.task.id);
      this.getTimeTrackingDetailsByTaskId(this.task.id);
    }
  }

  ngAfterViewInit() {
    this.timeEntry = null;

    if (this.task && this.task.isStarted) {
      this.timeEntry = new TimeEntry();
      this.timeEntry.startTime = this.task.taskStartTime;
      this.timeEntry.taskId = this.task.id;
      this.timeEntry.userId = this.authService.currentUserValue.id;
    }
  }

  ngOnInit(): void {
    const plugins = [
      mentionPlugin(this.apiService), // Pass ApiService to the plugin
    ];

    const editorOptions = {
      schema,
      plugins,
      keyboardShortcuts: true,
      inputRules: true,
    };

    this.editorDetails = new Editor(editorOptions);
    this.editorAcceptance = new Editor(editorOptions);
    this.editorComment = new Editor(editorOptions);

    this.loadTaskDetails();
  }

  loadTaskDetails() {
    this.getTaskStatus();

    this.getCaseAssignees(this.task.caseId).then((resp) => {
      if (this.task.id > 0 && this.task.assigneeIds.length > 0) {
        this.selectedAssignees = this.assignees.filter((assignee) => {
          const matchingTaskAssignee = this.task.taskAssignee.find(
            (taskAssignee) => taskAssignee.Id === assignee.id
          );
          if (matchingTaskAssignee) {
            assignee.estimatedHours =
              matchingTaskAssignee.EstimatedHours || assignee.estimatedHours;
            assignee.ratesPerHour =
              matchingTaskAssignee.RatesPerHour || assignee.ratesPerHour;
            assignee.selected = true;
          }
          return !!matchingTaskAssignee;
        });
      }
    });
  }

  getTaskStatus() {
    this.apiService.getAllTaskStatuses().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.taskStatuses = resp.data;
        } else {
          this.taskStatuses = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  getCommentsByTaskId(taskId: number) {
    this.apiService.getCommentsByTaskId(taskId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.comments = resp.data;
          this.isConversationStarted =
            this.comments && this.comments.length > 0;
        } else {
          this.comments = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }

  toggleConversation() {
    this.isConversationStarted = !this.isConversationStarted;
  }

  onSelect(event: FileSelectEvent) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }
    this.isDocUploading = true;
    this.isUploading = true;
    this.uploadMultipleFiles();
  }

  toggleCompletion(index: number): void {
    this.task.checklists[index].isCompleted =
      !this.task.checklists[index].isCompleted;
  }

  formatSize(bytes, decimals = 2) {
    return formatBytes(bytes, decimals);
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    this.editorDetails.destroy();
    this.editorAcceptance.destroy();
  }

  //#region  API CALLS / DATA FETCHERS
  getClients(term): Observable<any[]> {
    const filters = new ClientsFilter();
    filters.query = term;
    if (!term) {
      return new Observable<any[]>();
    }
    return this.apiService.getMatchingClientNames(filters).pipe(
      map((resp) => (resp.status === 'success' ? resp.data : [])),
      catchError((error) => {
        return of([]);
      })
    );
  }
  getClientById(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getClientById(id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.selectedClient = resp.data;
          } else {
            this.selectedClient = new Clients();
          }
          resolve();
        },
        error: (error) => {
          reject(error);
          // Handle error
        },
      });
    });
  }

  getCurrentCase(clientId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getCurrentCase(clientId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.case = resp.data;
          } else {
            this.case = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }
  getTags() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getTags().subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.tags = resp.data;
          } else {
            this.tags = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }
  getPriorityLevels() {
    this.apiService.getPriorityLevels().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.taskPriorties = resp.data;
        } else {
          this.taskPriorties = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  getAllUsers() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.assignees = resp.data;
          } else {
            this.assignees = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
          console.error('error:', error);
        },
      });
    });
  }
  getCaseAssignees(caseId) {
    return new Promise<void>((resolve, reject) => {
      this.apiService.getCaseAssignees(caseId).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.assignees = resp.data;
          } else {
            this.assignees = [];
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    });
  }
  getTaskActivitiesByTaskId(taskId: number): void {
    this.taskFilter.pageNumber = this.currentPage;
    this.taskFilter.pageSize = this.pageSize;
    this.taskFilter.id = taskId;
    this.apiService.GetAllRecentTaskActivities(this.taskFilter).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.taskActivities = resp.data;
        } else {
          this.taskActivities = [];
        }
      },
    });
  }

  getTimeTrackingDetailsByTaskId(taskId: number): void {
    this.apiService.getTimeTrackingDetailsByTaskId(taskId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.timeEntries = resp.data;
          this.totalHours =
            this.timeEntries?.length > 0
              ? calculateTotalHours(this.timeEntries)
              : '0';
        } else {
          this.timeEntries = [];
        }
      },
    });
  }
  updateTaskStatus(task: any) {
    this.taskStatus = new TaskStatus();
    this.taskStatus.id = task.taskStatusId;
    this.taskStatus.taskId = task.id;
    this.taskStatus.userId = this.authService.currentUserValue.id;
    this.apiService.updateTaskStatus(this.taskStatus).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.messageService.add({
            severity: 'success',
            summary: resp.status,
            detail: 'Task Status has been changed.',
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: resp.message,
            life: 3000,
          });
        }
      },
    });
  }
  // startTask() {
  //   const timeEntry = new TimeEntryReq();
  //   timeEntry.taskId = this.task.id;
  //   timeEntry.userId = this.authService.currentUserValue.id;

  //   return new Promise<Result>((resolve, reject) => {
  //     this.apiService.startTask(timeEntry).subscribe({
  //       next: (resp: Result) => {
  //         if (resp.status === 'success') {
  //           this.sharedService.triggerTaskStart(this.task);

  //           this.task.isStarted = true;
  //         } else {
  //           this.messageService.add({
  //             severity: 'error',
  //             summary: 'Error',
  //             detail: resp.message,
  //             life: 3000,
  //           });
  //         }
  //         resolve(resp);
  //       },
  //       error: (error) => {
  //         // Handle error
  //         reject(error);
  //       },
  //     });
  //   });
  // }
  initiateTaskStart(task: Task) {
    // Check if the current user is assigned to the task
    if (!task.assigneeIds.includes(this.currentUserId)) {
      return null;
    }

    // If hrmsPluginSettings is not defined, start the task without marking attendance
    if (!this.hrmsPluginSettings) {
      return this.startTask(task);
    }
    this.startTask(task);
    const { allowMarkAttendance, autoMarkAttendance, allowUserChoice } =
      this.hrmsPluginSettings;
      this.taskCheckIn = new TaskCheckInState();
    // Check attendance options
    if (allowMarkAttendance) {
      var type = 'Check In';
      if (autoMarkAttendance) {
        this.taskCheckIn.automark = true;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckIn(this.taskCheckIn);
        // Auto mark attendance logic can be added here if needed
      } else if (allowUserChoice) {
        this.currentTask = task;
        this.taskCheckIn.automark = false;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckIn(this.taskCheckIn);
        // this.attendanceConfirmationModal.message =
        //   "You're about to start this task. Would you like to mark your attendance for the day as well?";
        // this.attendanceConfirmationModal.showModal('check-in');
        // this.attendanceConfirmationModal.onConfirmed.subscribe((event) => {
        //   if (event.confirm) {
        //     this.markAttendance(event.type);
        //   }
        // });

        return null; // Exit after confirmation
      }
    }

    // Default to starting the task without marking attendance

    return null;
  }
  initiateTaskStop(task: Task) {
    // Check if the current user is assigned to the task
    if (!task.assigneeIds.includes(this.currentUserId)) {
      return null;
    }

    // If hrmsPluginSettings is not defined, start the task without marking attendance
    if (!this.hrmsPluginSettings) {
      return this.stopTask(task);
    }
    this.stopTask(task);
    const { allowMarkAttendance, autoMarkAttendance, allowUserChoice } =
      this.hrmsPluginSettings;
      this.taskCheckIn = new TaskCheckInState();
    // Check attendance options
    if (allowMarkAttendance) {
      var type = 'Check Out';
      if (autoMarkAttendance) {
        this.taskCheckIn.automark = true;
        this.taskCheckIn.clientId = task.clientId;
        this.sharedService.triggerTaskCheckOut(this.taskCheckIn);

        // Auto mark attendance logic can be added here if needed
      } else if (allowUserChoice) {
        this.taskCheckIn.automark = false;
        this.taskCheckIn.clientId = task.clientId;
        this.currentTask = task;
        this.sharedService.triggerTaskCheckOut(this.taskCheckIn);

        // this.attendanceConfirmationModal.message =
        //   "You're about to stop this task. Would you like to check out for the day?";
        // this.attendanceConfirmationModal.showModal('check-out');
        return null; // Exit after confirmation
      }
    }

    // Default to starting the task without marking attendance

    return null;
  }
  startTask(task: Task): Promise<Result> {
    if (!this.task.assigneeIds.includes(this.currentUserId)) {
      return null;
    }
    const timeEntry = new TimeEntryReq();

    return new Promise<Result>((resolve, reject) => {
      if (task.taskStatusId === 4) {
        this.confirmationService.confirm({
          header: 'Confirmation',
          icon: 'pi pi-info-circle',
          acceptIcon: 'none',
          rejectIcon: 'none',
          rejectButtonStyleClass: 'p-button-text',
          message:
            'Are you sure you want to initiate the task? This action will change its status from "On Hold" to "In Progress."',
          accept: () => {
            timeEntry.statusId = 2; // Change status to 2 when accepted
            this.executeStartTask(task, timeEntry, resolve, reject);
          },
          reject: () => {},
        });
      } else {
        timeEntry.statusId = task.taskStatusId;
        this.executeStartTask(task, timeEntry, resolve, reject);
      }
    });
  }
  async loadHrmsPluginConfig() {
    this.apiService.getHrmsPluginConfig().subscribe({
      next: (data: any) => {
        if (data) {
          if (data.isActive) {
            if (data.settings) {
              const obj = JSON.parse(data.settings);
              this.hrmsPluginSettings = obj;
            }
          }
        }
      },
      error: (error) => {},
    });
  }
  private executeStartTask(
    task: Task,
    timeEntry: TimeEntryReq,
    resolve: (value: Result) => void,
    reject: (reason?: any) => void
  ): void {
    if (timeEntry.statusId && timeEntry.statusId > 0) {
      timeEntry.taskId = task.id;
      timeEntry.userId = this.authService.currentUserValue.id;

      this.apiService.startTask(timeEntry).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            task.status = 'In Progress';
            task.taskStatusId = 2;
            task.taskStatusBgColor = '#ffc107';
            this.sharedService.triggerTaskStart(task);
            task.isStarted = true;
            this.task.isStarted = true;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve(resp);
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    } else {
      reject(new Error('Invalid status ID.'));
    }
  }

  stopTask(task: Task) {
    if (!this.task.assigneeIds.includes(this.currentUserId)) {
      return null;
    }
    const timeEntry = new TimeEntryReq();
    timeEntry.taskId = task.id;
    timeEntry.userId = this.authService.currentUserValue.id;

    return new Promise<void>((resolve, reject) => {
      this.apiService.stopTask(timeEntry).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.sharedService.triggerTaskStop(this.task.id);

            this.task.isStarted = false;
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve();
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }

  //#endregion  API CALLS / DATA FETCHERS

  editorDetailsFocusToggle(show: boolean) {
    this.editorDetailsMenu = show;
  }

  editorAcceptanceFocusToggle(show: boolean) {
    this.editorAcceptanceMenu = show;
  }

  editorCommentFocusToggle(show: boolean) {
    this.editorCommentMenu = show;
  }

  saveComment(): Promise<Result> {
    if (!this.jsonDoc) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Empty Comment',
        detail:
          'Comment cannot be empty. Please enter your comment before saving.',
        life: 3000,
      });
      return Promise.reject('No comment provided');
    }
    this.comment.commentText = toHTML(this.jsonDoc); // schema is optional
    this.comment.taskId = this.task.id;
    this.comment.userId = this.authService.currentUserValue.id;
    return new Promise<Result>((resolve, reject) => {
      this.apiService.addOrUpdateTaskComment(this.comment).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.getCommentsByTaskId(this.task.id);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve(resp);
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }

  validateAndSetData() {
    if (this.jsonDoc) {
      this.saveComment().then((resp) => {
        return true;
      });
    }

    this.task.description = toHTML(this.jsonDescriptionDoc);
    this.task.acceptanceCriteria = toHTML(this.jsonAcceptanceDoc);
    this.task.assigneeIds = this.selectedAssignees.map((x) => x.id);
    this.task.documentsJSON = JSON.stringify(this.task.documents);
    //MODIFICATION NEEDED ON MAIN SAVE BUTTON
    this.task.checkListJSON = JSON.stringify(this.task.checklists);
    this.task.createdBy = this.authService.currentUserValue.id;
    return true;
  }

  saveCheckList(): Promise<Result> {
    this.checkList.taskId = this.task.id;
    this.checkList.checkListJSON = JSON.stringify(this.task.checklists);

    return new Promise<Result>((resolve, reject) => {
      this.apiService.addOrUpdateCheckList(this.checkList).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          resolve(resp);
        },
        error: (error) => {
          // Handle error
          reject(error);
        },
      });
    });
  }

  onSubmit() {
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addOrUpdateTask(this.task).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: resp.status,
              detail: resp.message,
              life: 3000,
            });
            this.closeModal();
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          this.isDataSaving = false;
          console.error('error:', error);
        },
        complete: () => {
          console.info('called successfully insertAddUpdateTask');
        },
      });
    }
  }

  closeModal(): void {
    this.onModalClosed.emit();
    this.dialogRef.close();
  }
  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  uploadMultipleFiles(): void {
    this.apiService.getAwsCredentials('Class786--').subscribe({
      next: (resp: any) => {
        if (resp.status === 'success') {
          this.credentials = resp.data;

          const uploadPromises = this.uploadedFiles.map((file) => {
            const taskDocument = new TaskDocument();
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            taskDocument.documentName = fileNameWithoutExtension;
            const fileExtension = file.name.split('.').pop();
            taskDocument.fileExt = fileExtension;
            taskDocument.size = file.size;

            return this.uploadFile(file).then((url) => {
              taskDocument.fileLocation = url;
              this.task.documents.push(taskDocument);
              return url;
            });
          });

          Promise.all(uploadPromises)
            .then(() => {
              this.uploadedFiles = [];
              this.isUploading = false;
              this.messageService.add({
                severity: 'info',
                summary: 'Files Uploaded',
                detail: 'All files uploaded successfully.',
              });
            })
            .catch((error) => {
              console.error('Error uploading files:', error);
            });
        }
      },
      error: (error) => {
        console.error('Error fetching AWS credentials:', error);
      },
    });
  }

  uploadFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const today = new Date();
      const filePath = `tms/org_${this.authService.currentUserValue.organizationId}/tasks/taskDocuments/${file.name}_${today.getDate()}_${today.getMilliseconds()}`;

      const bucket = new S3({
        accessKeyId: this.credentials.accessKey,
        secretAccessKey: this.credentials.secretKey,
        region: this.credentials.region,
      });

      const params = {
        Bucket: this.credentials.bucketName,
        Key: filePath,
        Body: file,
        ACL: 'public-read',
        ContentType: file.type,
      };

      bucket.upload(params)
        .on('httpUploadProgress', (evt) => {
          this.progress = Math.round((100 * evt.loaded) / evt.total);
        })
        .send((err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data.Location);
          }
        });
    });
  }

  triggerFileUpload() {
    this.fileUpload.choose();  // Trigger the file input click
  }
  onRemove(event: any) {
    const index = this.uploadedFiles.findIndex(file => file.name === event.file.name);
    if (index !== -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  onRemoveFile(file: TaskDocument) {
    this.isDocUploading = true;
    const index = this.task.documents.indexOf(file);
    if (index > -1) {
      this.task.documents.splice(index, 1);
    }
  }
  onRemoveUploadedFile(file: any) {
    const index = this.uploadedFiles.indexOf(file);
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }
  removeDocs(){
    this.isDocUploading = false;
    this.progress = 0;
   this.uploadedFiles = [];
   if(this.task.documentsJSON !== null){
    this.task.documents = parseJSONToLowerCaseFirstChar(
      this.task.documentsJSON
    );
  }
 else{
  this.task.documents =[];
 }
  }
  saveTaskDocs(){
    this.taskDocs = new AddOrUpdateTaskDocs();
    this.taskDocs.taskId = this.task.id;
    this.taskDocs.userId = this.authService.currentUserValue.id;
    this.taskDocs.documentJSON = JSON.stringify(this.task.documents);
    this.apiService.addOrUpdateTaskDocs(this.taskDocs).subscribe({
      next: (resp: any) => {
        this.isDocUploading = false;
        if (resp.status === 'success') {
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Tasks document added successfully',
              });

        }
      },
      error: (error) => {
        console.error('Error fetching AWS credentials:', error);
      },
    });
  }

}
