import { Component, Renderer2 } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { randomColors } from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { UserReport } from 'src/app/Models/UserReport';
import { padZero } from 'src/app/utils/utils';
import { Task } from 'src/app/Models/Task';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrl: './user-report.component.css'
})
export class UserReportComponent {
  overview: UserReport = new UserReport();
  selectedDate: Date = new Date();
  usersList: User[] = [];
  selectedAssignees: User;
  selectedUserId: number = 0;

  constructor(
    private renderer: Renderer2,
    private authService:AuthService,
    public router: Router,
    private apiService: ApiService
  ) {
    this.selectedDate = new Date();
  }

  ngOnInit() {
    this.applyFilter();
    this.getAllUsers();
    this.loadData().then((t) => {
      this.loadMonthlyActualvsEstimatedCostChart(t.summary);
    });
  }

  ngAfterViewInit() {}

  loadData(): Promise<UserReport> {
      return new Promise<UserReport>((resolve, reject) => {
        if(this.selectedAssignees){
        this.apiService
          .getUsersReport(
            this.selectedDate.getMonth() + 1,
            this.selectedDate.getFullYear(),
            this.selectedAssignees.id.toString()
          )
          .subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                debugger;
                this.overview = resp.data;
  
                if (this.overview) {
                  // You can add any additional logic here if needed
                }
  
                resolve(this.overview); // Resolve the promise when the operation is successful
              } else {
                // Handle cases where the response status is not 'success'
                reject(new Error('Failed to get totals: ' + resp.status));
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
              reject(error); // Reject the promise on error
            },
          });
        }
      });
  }

  applyFilter() {
    this.loadData().then((t) => {
      this.loadMonthlyActualvsEstimatedCostChart(t.summary);
    });
  }
  public getAllUsers() {
    this.apiService.getAllUsers(GlobalVariables.selectedProjectId).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          if(this.authService.hasPermission('user-report'))
            {
              this.usersList = [{ id: 0, name: 'None' }, ...resp.data];
              this.selectedAssignees = this.usersList[0];
            }
            else{
              this.usersList = resp.data.filter(x => x.id === this.authService.currentUserValue.id);
              this.selectedAssignees = this.usersList[0];
            }


        } else {
          this.usersList = resp.data;
        }
      },
      error: (error) => {
        // Handle error
        console.error('error:', error);
      },
    });
  }

  onUserSelect($event: any) {
    this.selectedUserId = $event.value.id;
  }

  chartOptionsCost: any = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return tooltipItem.dataset.label + ': Rs. ' + value.toLocaleString();
          }
        }
      },
      legend: {
        display: false,
      },
    },
    aspectRatio: 3.5, // Adjust this ratio to control the height of the chart area
  };
//#region Monthly Actual vs Estimated Cost

monthlyActualvsEstimatedCostData: any = null;

loadMonthlyActualvsEstimatedCostChart(trend) {

  // Create canvas element
  let canvas = this.renderer.createElement('canvas');

  // Get 2D context for the canvas
  let ctx = canvas.getContext('2d');

  // Dynamically set gradient based on the canvas size
  const gradientCosts = ctx.createLinearGradient(0, 0, 0, canvas.height);
  gradientCosts.addColorStop(0, 'rgba(0, 255, 17, 0.2)'); // Gradient for Actual Costs
  gradientCosts.addColorStop(1, 'rgba(0, 255, 17, 0)');

  const gradientHours = ctx.createLinearGradient(0, 0, 0, canvas.height);
  gradientHours.addColorStop(0, 'rgba(255, 0, 0, 0.2)'); // Gradient for Total Hours
  gradientHours.addColorStop(1, 'rgba(255, 0, 0, 0)');

  // Extract data from trend array
  const months = trend.map((t) => t.monthName);
  const actualCosts = trend.map((t) => t.totalCost);
  const actualHours = trend.map((t) => t.totalHours);

  // Chart Data Configuration
  this.monthlyActualvsEstimatedCostData = {
    labels: months,  // x-axis labels (months)
    datasets: [
      {
        label: 'Actual Costs',          // Label for the Actual Costs dataset
        fontFamily: 'Poppins',          // Font style
        data: actualCosts,              // Data points for Actual Costs
        fill: true,                     // Enable fill under the line
        pointBorderWidth: 1,            // Border width of the points
        pointRadius: actualCosts.map(() => 2), // Dynamic point radius
        pointHoverRadius: actualCosts.map(() => 4), // Hover radius
        tension: 0.4,                   // Line tension (smoothing)
        borderDash: [5, 5],             // Dotted line style
        borderWidth: 1.5,               // Line width
        borderColor: 'rgba(0, 255, 17)', // Line color (matching gradient)
        backgroundColor: gradientCosts, // Gradient fill for Actual Costs
        yAxisID: 'y',                    // Use the primary y-axis
      },
      {
        label: 'Total Hours',            // Label for the Total Hours dataset
        type: 'bar',                     // Change this to 'bar' for a different representation
        fontFamily: 'Poppins',           // Font style
        data: actualHours,               // Data points for Total Hours
        fill: true,                      // Enable fill under the bars (if needed)
        borderWidth: 1.5,                // Bar width
        backgroundColor: gradientHours,  // Gradient fill for Total Hours
        yAxisID: 'y1',                   // Use the secondary y-axis
      },
    ],
  };

  // Chart Options Configuration
  this.chartOptionsCost = {
    responsive: true,
    scales: {
      y: {
        type: 'linear',
        position: 'left',
      },
      y1: {
        type: 'linear',
        position: 'right',
        grid: {
          drawOnChartArea: false,  // Only want the grid lines for one axis to show up
        },
        ticks: {
          // Customize ticks for better visibility if needed
          callback: function(value) {
            return value + 'h'; // Append 'h' to hour values
          },
        },
      },
    },
  };
}    
padZero(id: number, minLength: number) {
  return '#' + padZero(id, minLength);
}

getStatusClass(task: Task) {
  if (task.taskStatusId === 1) {
    return 'not-started';
  } else if (task.taskStatusId === 2) {
    return 'inprogress';
  } else if (task.taskStatusId === 3) {
    return 'completed';
  } else if (task.taskStatusId === 4) {
    return 'onhold';
  }

  return '';
}

getCompletionStatus(item: Task): string {
  const now = new Date();
  const dueDate = new Date(item.dueDate);
  const completionDate = item.completionDate
    ? new Date(item.completionDate)
    : null;

  if (item.taskStatusId === 3) {
    // Task is completed; check if it was completed on time or early
    if (completionDate && completionDate < dueDate) {
      return 'Completed Early';
    } else if (completionDate && completionDate > dueDate) {
      return 'Overdue';
    } else {
      return 'On Track';
    }
  } else {
    // Task is not completed; check if it is on track or overdue
    return dueDate >= now ? 'On Track' : 'Overdue';
  }
}
showTasks(){

  const userId = this.selectedAssignees.id;

  // Get the selected month and year from `selectedDate`
  const year = this.selectedDate.getFullYear();
  const month = this.selectedDate.getMonth() + 1; // JS months are 0-based, hence +1

  // Start date: first day of the selected month
  const startDate = new Date(year, this.selectedDate.getMonth(), 1); // YYYY-MM-01

  // End date: last day of the selected month
  const endDate = new Date(year, month, 0); // The 0th day of next month gives the last day of the current month

  // Format the dates (if necessary for routing, e.g., 'YYYY-MM-DD' format)
  const formattedStartDate = this.formatDate(startDate);
  const formattedEndDate = this.formatDate(endDate);

  // Route to the task page with these filters
  // this.router.navigate(['/tasks-list'], {
  //   queryParams: {
  //     userId: userId,
  //     fromDate: formattedStartDate,
  //     toDate: formattedEndDate
  //   }
  // });
}
// Helper function to format date as 'YYYY-MM-DD'
formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two-digit month
  const day = ('0' + date.getDate()).slice(-2); // Ensure two-digit day
  return `${year}-${month}-${day}`;
}
//#endregion

}
