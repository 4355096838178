<div class="task-timer-container" *ngIf="timeEntry && !showTimeOnly">
  <div class="task-timer-strip">
    <div class="task-timer-info">
      <span>Current Task: <strong>{{ padZero(timeEntry.taskId, 3) }}</strong></span>
      <span class="elapsed-time" [style.width]="!width ? '150px' : width + 'px'">Elapsed Time: <span
          style="font-weight: 600;color: #ff8d00;letter-spacing: 1px;">{{ elapsedTime | elapsedTimePipe }}</span></span>
    </div>
    <button class="action-button-danger stop-button " (click)="initiateTaskStop()">Stop Task</button>
  </div>
</div>

<div class="time-only" [style.width]="!width ? '70px' : width + 'px'" style="color: #ff8d00;"
  *ngIf="timeEntry && showTimeOnly">{{ elapsedTime | elapsedTimePipe }}</div>

