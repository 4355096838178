import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ManageLeadsComponent } from './components/leads/manageleads/manage-leads.component';
import {
  NgbActiveModal,
  NgbModule,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxEditorModule } from 'ngx-editor';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxTranslateModule } from './translate/translate.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { TabViewModule } from 'primeng/tabview';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { ListboxModule } from 'primeng/listbox';
import { CardModule } from 'primeng/card';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MasterComponent } from './components/layout/master/master.component';
import { LoginComponent } from './components/login/login.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ToastModule } from 'primeng/toast';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmDialogComponent } from './components/modals/confirm-dialog/confirm-dialog.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { LeadviewComponent } from './components/leads/leadview/leadview.component';
import { AlertDialogComponent } from './components/modals/alert-dialog/alert-dialog.component';
import { LeadsFiltersComponent } from './components/leads/manageleads/leads-filters/leads-filters.component';
import { AuthGaurd } from './gaurds/auth-guard';
import { DealsFiltersComponent } from './components/deals/managedeals/deals-filters/deals-filters.component';
import { ManageDealsComponent } from './components/deals/managedeals/manage-deals.component';
import { DealviewComponent } from './components/deals/dealview/dealview.component';
import { ManageEventsComponent } from './components/events/manageevents/manageevents.component';
import { EventViewComponent } from './components/events/eventview/eventview.component';
import {
  CalendarModule as AngularCalendarModule,
  DateAdapter,
} from 'angular-calendar';
import { CalendarModule as PrimeNGCalendarModule } from 'primeng/calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AddEventComponent } from './components/events/addevent/addevent.component';
import { AdddealsComponent } from './components/deals/adddeals/adddeals.component';
import { OverallAnalyticsComponent } from './components/analytics/overall-analytics/overall-analytics.component';
import { LeadsAnalyticsComponent } from './components/analytics/leads-analytics/leads-analytics.component';
import { AddupdateleadComponent } from './components/leads/addupdatelead/addupdatelead.component';
import { NodatafoundComponent } from './components/layout/Error/nodatafound/nodatafound.component';
import { NoactivityComponent } from './components/layout/Error/noactivity/noactivity.component';
import { ProjectLoadingIndicatorComponent } from './components/common/project-loading-indicator/project-loading-indicator.component';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DatePipe } from '@angular/common';
import { ManageUsersComponent } from './components/users/manage-users/manage-users.component';
import { UserSetupComponent } from './components/users/user-setup/user-setup.component';
import { SettingsComponent } from './components/settings/settings.component';
import { InvitationComponent } from './components/invitation/invitation.component';
import { ProjectsComponent } from './components/projects/manage-projects/projects.component';
import { ProjectSetupComponent } from './components/projects/project-setup/project-setup.component';
import { ClientListComponent } from './components/clients/client-list/client-list.component';
import { ClientSetupComponent } from './components/clients/client-setup/client-setup.component';
import { ClientProfileComponent } from './components/clients/client-profile/client-profile.component';
import { ClientFiltersComponent } from './components/clients/client-list/client-filters/client-filters.component';
import { ServicesComponent } from './components/services/manage-services/services.component';
import { ServicesSetupComponent } from './components/services/services-setup/services-setup.component';
import { CaseSetupComponent } from './components/clients/client-cases/case-setup/case-setup.component';
import { ManageTasksComponent } from './components/tasks/manage-tasks/manage-tasks.component';
import { TaskSetupComponent } from './components/tasks/task-setup/task-setup.component';
import { ManageCasesComponent } from './components/clients/client-cases/manage-case/manage-case.component';
import { DocumentSetupComponent } from './components/clients/client-documents/document-setup/document-setup/document-setup.component';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ClientDocumentListComponent } from './components/clients/client-documents/client-document-list/client-document-list.component';
import { TreeModule } from 'primeng/tree';
import { TaskListComponent } from './components/tasks/task-list/task-list.component';
import { TaskFiltersComponent } from './components/tasks/task-list/task-filters/task-filters.component';
import { TaskActivityComponent } from './components/tasks/task-activity/task-activity.component';
import { TaskActivityFiltersComponent } from './components/tasks/task-activity/task-activity-filters/task-activity-filters.component';
import { TaskViewComponent } from './components/tasks/task-view/task-view.component';
import { ApiService } from './services/api.service';
import { TaskTimerComponent } from './components/tasks/task-timer/task-timer.component';
import { MonthlyCalendarComponent } from './components/time-tracking/monthly-calendar/monthly-calendar.component';
import { TimeSheetComponent } from './components/time-tracking/time-sheet/time-sheet.component';
import { OverviewComponent } from './components/time-tracking/overview/overview.component';
import { CostExplorerComponent } from './components/cost-explorer/cost-explorer/cost-explorer.component';
import { TimeDifferencePipe } from './pipes/time-difference.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ElapsedTimePipe } from './pipes/elapsed-time-pipe.pipe';
import { TimeEntriesComponent } from './components/time-tracking/time-entries/time-entries.component';
import { ClientsBudgetsComponent } from './components/cost-explorer/clients-budgets/clients-budgets.component';
import { CostDetailsComponent } from './components/cost-explorer/cost-details/cost-details.component';
import { BillingSettingsSetupComponent } from './components/clients/client-cases/billing-settings-setup/billing-settings-setup.component';
import { ManageAssigneeComponent } from './components/clients/client-cases/manage-assignee/manage-assignee.component';
import { IncompletesetupComponent } from './components/layout/Error/incompletesetup/incompletesetup.component';
import { OnlyNumbersDirectiveDirective } from './directives/only-numbers-directive.directive';
import { SpecialCharsBlockerDirective } from './directives/special-chars-blocker.directive';
import { EmailValidatorDirective } from './directives/email-validator-directive.directive';
import { PhoneValidatorDirective } from './directives/phone-validator.directive';
import { InputFieldValidatorDirective } from './directives/name-validator.directive';
import { TasksOverviewComponent } from './components/tasks/tasks-overview/tasks-overview.component';
import { ClientShareFiltersComponent } from './components/clients/client-share-filters/client-share-filters.component';
import { CircleProgressComponent } from './components/common/circle-progress/circle-progress.component';
import { HrmsPluginComponent } from './plugins/hrms/hrms-plugin/hrms-plugin.component';
import { AttendanceConfirmationComponent } from './components/common/attendance-confirmation/attendance-confirmation.component';
import { HasPermissionDirective } from './directives/permission.directive';
import { ManageRolesComponent } from './components/roles-and-permissions/manage-roles/manage-roles.component';
import { RoleSetupComponent } from './components/roles-and-permissions/role-setup/role-setup.component';
import { PermissionListComponent } from './components/roles-and-permissions/permission-list/permission-list.component';
import { RolePermissionsListComponent } from './components/roles-and-permissions/role-permissions-list/role-permissions-list.component';
import { ToastComponent } from './components/common/toast/toast.component';
import { TasksTemplateComponent } from './components/tasks/tasks-template/tasks-template.component';
import { ManageTeamsComponent } from './components/users/manage-teams/manage-teams.component';
import { ClientLocationsComponent } from './components/clients/client-locations/client-locations.component';
import { ClientSetupLocationComponent } from './components/clients/client-setup-location/client-setup-location.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotAuthorizedComponent } from './components/layout/Error/not-authorized/not-authorized.component';
import { TeamSetupComponent } from './components/users/team-setup/team-setup.component';
import { ManageTasksTemplateComponent } from './components/tasks/manage-tasks-template/manage-tasks-template.component';
import { TasksTemplateSetupComponent } from './components/tasks/tasks-template-setup/tasks-template-setup.component';
import { AddTasksToTemplateComponent } from './components/tasks/add-tasks-to-template/add-tasks-to-template.component';
import { RequiredDirective } from './directives/required.directive';
import { TaskTemplateListComponent } from './components/tasks/task-template-list/task-template-list.component';
import { TaskAddTemplateComponent } from './components/tasks/task-add-template/task-add-template.component';
import { UserReportComponent } from './components/reports/user-report/user-report.component';
import { TeamReportComponent } from './components/reports/team-report/team-report.component';
@NgModule({ declarations: [
        AppComponent,
        ManageLeadsComponent,
        ManageDealsComponent,
        MasterComponent,
        LoginComponent,
        ConfirmDialogComponent,
        SidebarComponent,
        LeadviewComponent,
        DealviewComponent,
        AlertDialogComponent,
        LeadsFiltersComponent,
        ClientFiltersComponent,
        DealsFiltersComponent,
        ManageEventsComponent,
        EventViewComponent,
        AddEventComponent,
        AdddealsComponent,
        OverallAnalyticsComponent,
        LeadsAnalyticsComponent,
        AddupdateleadComponent,
        NodatafoundComponent,
        NoactivityComponent,
        ProjectLoadingIndicatorComponent,
        ManageUsersComponent,
        UserSetupComponent,
        SettingsComponent,
        InvitationComponent,
        ProjectsComponent,
        ProjectSetupComponent,
        ServicesComponent,
        ServicesSetupComponent,
        ClientListComponent,
        ClientSetupComponent,
        ClientProfileComponent,
        CaseSetupComponent,
        ManageTasksComponent,
        TaskSetupComponent,
        ManageCasesComponent,
        DocumentSetupComponent,
        ClientDocumentListComponent,
        TaskListComponent,
        TaskFiltersComponent,
        TaskActivityComponent,
        TaskActivityFiltersComponent,
        TaskViewComponent,
        TaskTimerComponent,
        MonthlyCalendarComponent,
        TimeSheetComponent,
        OverviewComponent,
        CostExplorerComponent,
        TimeDifferencePipe,
        SafeHtmlPipe,
        ElapsedTimePipe,
        TimeEntriesComponent,
        ClientsBudgetsComponent,
        CostDetailsComponent,
        BillingSettingsSetupComponent,
        ManageAssigneeComponent,
        IncompletesetupComponent,
        OnlyNumbersDirectiveDirective,
        SpecialCharsBlockerDirective,
        EmailValidatorDirective,
        PhoneValidatorDirective,
        InputFieldValidatorDirective,
        HasPermissionDirective,
        RequiredDirective,
        TasksOverviewComponent,
        ClientShareFiltersComponent,
        CircleProgressComponent,
        HrmsPluginComponent,
        AttendanceConfirmationComponent,
        ManageRolesComponent,
        RoleSetupComponent,
        PermissionListComponent,
        RolePermissionsListComponent,
        ToastComponent,
        TasksTemplateComponent,
        ManageTeamsComponent,
        ClientLocationsComponent,
        ClientSetupLocationComponent,
        NotAuthorizedComponent,
        TeamSetupComponent,
        ManageTasksTemplateComponent,
        TasksTemplateSetupComponent,
        AddTasksToTemplateComponent,
        TaskTemplateListComponent,
        TaskAddTemplateComponent,
        UserReportComponent,
        TeamReportComponent,
    ],
    exports: [
        HasPermissionDirective, // Export the directive if it's used across multiple modules
    ],
    bootstrap: [AppComponent], imports: [TreeModule,
        CascadeSelectModule,
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgxEditorModule.forRoot({
            locals: {
                // menu
                bold: 'Bold',
                italic: 'Italic',
                code: 'Code',
                underline: 'Underline',
                strike: 'Strike',
                blockquote: 'Blockquote',
                bullet_list: 'Bullet List',
                ordered_list: 'Ordered List',
                heading: 'Heading',
                h1: 'Header 1',
                h2: 'Header 2',
                h3: 'Header 3',
                h4: 'Header 4',
                h5: 'Header 5',
                h6: 'Header 6',
                align_left: 'Left Align',
                align_center: 'Center Align',
                align_right: 'Right Align',
                align_justify: 'Justify',
                text_color: 'Text Color',
                background_color: 'Background Color',
                horizontal_rule: 'Horizontal rule',
                format_clear: 'Clear Formatting',
                insertLink: 'Insert Link',
                removeLink: 'Remove Link',
                insertImage: 'Insert Image',
                indent: 'Increase Indent',
                outdent: 'Decrease Indent',
                superscript: 'Superscript',
                subscript: 'Subscript',
                undo: 'Undo',
                redo: 'Redo',
                // pupups, forms, others...
                url: 'URL',
                text: 'Text',
                openInNewTab: 'Open in new tab',
                insert: 'Insert',
                altText: 'Alt Text',
                title: 'Title',
                remove: 'Remove',
                enterValidUrl: 'Please enter a valid URL',
            },
        }),
        InputTextareaModule,
        ListboxModule,
        CardModule,
        SelectButtonModule,
        FileUploadModule,
        TabViewModule,
        BadgeModule,
        AvatarModule,
        TableModule,
        DialogModule,
        OverlayPanelModule,
        ConfirmDialogModule,
        MultiSelectModule,
        ProgressBarModule,
        TooltipModule,
        ChartModule,
        ToastModule,
        NgbModule,
        NgbDatepickerModule,
        NgbAlertModule,
        BrowserAnimationsModule,
        NgxTranslateModule,
        ToastrModule.forRoot(), // ToastrModule added
        FlatpickrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        DropdownModule,
        CheckboxModule,
        SidebarModule,
        AutoCompleteModule,
        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        PrimeNGCalendarModule,
        GoogleMapsModule], providers: [
        NgbActiveModal,
        AuthGaurd,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        ConfirmationService,
        MessageService,
        DatePipe,
        ApiService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}
