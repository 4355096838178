import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { CaseStatistics } from 'src/app/Models/CostAndHoursMonthlyTrend';
import {
  TaskCost,
  CostingFilters,
  TaskCostBreakout,
} from 'src/app/Models/TaskCost';
import { Result } from 'src/app/Models/Result';
import { User } from 'src/app/Models/Users';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  padZero,
  parseJSONToLowerCaseFirstChar,
  printDiv,
} from 'src/app/utils/utils';
import { GlobalVariables } from 'src/environments/environment';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
@Component({
  selector: 'app-cost-details',
  templateUrl: './cost-details.component.html',
  styleUrls: ['./cost-details.component.css'],
  providers: [CurrencyPipe],
})
export class CostDetailsComponent {
  costingData: TaskCost[] = [];
  costingBreakoutItems: TaskCostBreakout[] = [];
  filters: CostingFilters = new CostingFilters();
  startDate: any | null;
  endDate: any | null;
  isBillable: any = '';
  expandedRows = {};
  isLoading = true;
  filterVisible = false;
  caseCount: number = 0; //zero represents null in api level which will return all data;
  caseWiseData: CaseStatistics[] = [];
  currentYear: number = new Date().getFullYear();
  dataTypeId: number = 1; // Example typeId, this can be set dynamically
  reportTypes: { id: number; label: string }[] = [
    { id: 1, label: 'Task Wise' },
    { id: 2, label: 'Case Wise' },
  ];
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private currencyPipe: CurrencyPipe
  ) {
    this.resetFilters();
  }

  ngOnInit(): void {
    this.getCostingData();
  }

  getCostingData(): void {
    this.isLoading = true;
    this.apiService.getCostingData(this.filters).subscribe({
      next: (resp: { status: string; data: TaskCost[] }) => {
        if (resp.status === 'success') {
          this.costingData = resp.data;
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching costing data:', error);
      },
    });
  }

  getCostingBreakout(taskId: number): void {
    const task = this.costingData.find((item) => item.taskId === taskId);
    task.isLoadingBreakout = true;

    this.apiService.getCostingBreakout(taskId).subscribe({
      next: (resp: { status: string; data: TaskCostBreakout[] }) => {
        if (resp.status === 'success') {
          this.costingBreakoutItems = resp.data;

          if (task) {
            task.breakOut = this.costingBreakoutItems;
            task.taskWarnings = this.getTaskIssues(task);

            task.isLoadingBreakout = false;
          }

          //
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.error('Error fetching costing data:', error);
      },
    });
  }

  getTaskMessages(task: TaskCost, cellType: string): any {
    const message = { type: '', details: '' };
    const totalHoursExceeded = task.totalHours > task.estimatedHours;
    const totalCostExceeded = task.totalCost > task.estimatedCost;

    if (cellType === 'hours') {
      if (totalHoursExceeded) {
        message.type = 'warn';
        message.details = `Total hours (${task.totalHours.toFixed(
          2
        )}) exceed estimated hours (${task.estimatedHours.toFixed(2)})`;
      } else {
        message.type = 'info';
        message.details = `Estimated hours (${task.estimatedHours.toFixed(2)})`;
      }
    }

    if (cellType === 'cost') {
      if (totalCostExceeded) {
        message.type = 'warn';
        message.details = `Total cost (${this.currencyPipe.transform(
          Number(task.totalCost),
          'Rs '
        )}) exceeds estimated cost (${this.currencyPipe.transform(
          task.estimatedCost,
          'Rs '
        )})`;
      } else {
        if (totalHoursExceeded && task.totalCost === 0 && task.isBillable) {
          message.type = 'warn';
          message.details = `This task has a configuration issue: Total hours (${task.totalHours.toFixed(
            2
          )}) exceeded the estimated hours (${task.estimatedHours.toFixed(
            2
          )}), and the total cost is zero for a billable task.`;
        } else {
          message.type = 'info';
          message.details = `Estimated cost (${this.currencyPipe.transform(
            task.estimatedCost,
            'Rs '
          )})`;
        }
      }
    }

    return message;
  }
  getCaseMessages(caseStat: CaseStatistics, cellType: string): any {
    const message = { type: '', details: '' };
    const totalHoursExceeded = caseStat.hoursSpent > caseStat.estimatedHours;
    const totalCostExceeded = caseStat.totalCost > caseStat.estimatedCost;

    if (cellType === 'hours') {
      if (totalHoursExceeded) {
        message.type = 'warn';
        message.details = `Total hours (${caseStat.hoursSpent.toFixed(
          2
        )}) exceed estimated hours (${caseStat.estimatedHours.toFixed(2)})`;
      } else {
        message.type = 'info';
        message.details = `Estimated hours (${caseStat.estimatedHours.toFixed(2)})`;
      }
    }

    if (cellType === 'cost') {
      if (totalCostExceeded) {
        message.type = 'warn';
        message.details = `Total cost (${this.currencyPipe.transform(
          Number(caseStat.totalCost),
          'Rs '
        )}) exceeds estimated cost (${this.currencyPipe.transform(
          caseStat.estimatedCost,
          'Rs '
        )})`;
      } 
      // else {
      //   if (totalHoursExceeded && caseStat.totalCost === 0 && caseStat.isBillable) {
      //     message.type = 'warn';
      //     message.details = `This task has a configuration issue: Total hours (${caseStat.hoursSpent.toFixed(
      //       2
      //     )}) exceeded the estimated hours (${caseStat.estimatedHours.toFixed(
      //       2
      //     )}), and the total cost is zero for a billable task.`;
      //   } else {
      //     message.type = 'info';
      //     message.details = `Estimated cost (${this.currencyPipe.transform(
      //       caseStat.estimatedCost,
      //       'Rs '
      //     )})`;
      //   }
      // }
    }

    return message;
  }
  getTaskIssues(task: TaskCost) {
    const issues = [];
    const hoursIssue = this.getTaskMessages(task, 'hours');
    const costIssue = this.getTaskMessages(task, 'cost');

    if (hoursIssue) {
      issues.push(hoursIssue);
    }

    if (costIssue) {
      issues.push(costIssue);
    }

    return issues;
  }

  getCaseStatisticsByOrgId(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiService
        .getCaseStatisticsByOrgId(this.caseCount, this.currentYear)
        .subscribe({
          next: (resp: Result) => {
            if (resp.status === 'success') {
              debugger;
              this.caseWiseData = resp.data;
              resolve();
            } else {
              // Handle cases where the response status is not 'success'
              reject(new Error('Failed to get totals: ' + resp.status));
            }
          },
          error: (error) => {
            // Handle error
            console.error('error:', error);
            reject(error); // Reject the promise on error
          },
        });
    });
  }

  dataTypeChange(event: SelectButtonChangeEvent) {
    this.dataTypeId = Number(event.value);

    if (this.dataTypeId === 1) {
      this.getCostingData();
    } else {
      this.getCaseStatisticsByOrgId();
    }
  }

  applyFilter() {
    if (this.startDate) {
      this.filters.startDate = new Date(
        this.startDate.year,
        this.startDate.month - 1,
        this.startDate.day
      );
    }
    if (this.endDate) {
      this.filters.endDate = new Date(
        this.endDate.year,
        this.endDate.month - 1,
        this.endDate.day
      );
    }

    this.filters.isBillable =
      this.isBillable === '' ? null : this.isBillable === 'true';

    this.getCostingData();
  }

  resetFilters() {
    const today = new Date();
    this.filters.startDate = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );

    this.filters.endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23, 59, 59
    );

    this.filters.billableTypeId = 0;
    this.filters.clientName = '';
    this.filters.taskTitle = '';
    this.filters.isBillable = null;
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }

  toggleExpand(task: TaskCost): void {
    this.collapseAll(task);
    task.expanded = !task.expanded;
    if (task.expanded && !task.breakOut) {
      this.getCostingBreakout(task.taskId);
    }
  }

  expandAll() {}

  collapseAll(task: TaskCost) {
    this.costingData.forEach((item) => {
      if (item != task) item.expanded = false;
    });
  }

  print() {
    const id =
      this.dataTypeId === 1 ? 'cost-detail-table' : 'case-wise-detail-table';
    printDiv(id);
  }
  exportCostDetails(){
    if (this.dataTypeId === 1) {
      this.apiService.getCostingDataForExcel(this.filters).subscribe({
        next: (resp: Blob) => {
          const fileName = 'Cost_Details_Report.xlsx';
          const blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
          // Create a link element, use it to download the file
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = fileName;
      
          // Append the link to the DOM and simulate a click
          document.body.appendChild(link);
          link.click();
      
          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        },
        error: (error) => {
          console.error('Error downloading the file:', error);
        }
      });
      } else {
        this.apiService.getCaseStatisticsForExcelByOrgId(this.caseCount, this.currentYear).subscribe({
          next: (resp: Blob) => {
            const fileName = 'Case_Wise_Details_Report.xlsx';
            const blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
            // Create a link element, use it to download the file
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
        
            // Append the link to the DOM and simulate a click
            document.body.appendChild(link);
            link.click();
        
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
          },
          error: (error) => {
            console.error('Error downloading the file:', error);
          }
        });
      }
  }
}
