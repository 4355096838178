import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Task, TaskCheckInState, TimeEntry } from 'src/app/Models/Task';
import { Result } from 'src/app/Models/Result';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { padZero } from 'src/app/utils/utils';
import { AttendanceConfirmationComponent } from '../../common/attendance-confirmation/attendance-confirmation.component';
import { HrmsPluginSettings } from 'src/app/Models/HrmsPluginSettings';
import { CheckInRequestDetail } from 'src/app/Models/CheckInRequestDetail';
import { ConfirmationService, MessageService } from 'primeng/api';
declare var $: any;
@Component({
  selector: 'app-task-timer',
  templateUrl: './task-timer.component.html',
  styleUrls: ['./task-timer.component.css'],
})
export class TaskTimerComponent implements AfterViewInit {
  @ViewChild(AttendanceConfirmationComponent)
  attendanceConfirmationModal: AttendanceConfirmationComponent;
  markAttendanceDetails: CheckInRequestDetail;
  @Input() width: Number | undefined;
  @Input() showTimeOnly: boolean | undefined;
  @Input() timeEntry: TimeEntry | undefined;

  startTime: Date;
  elapsedTime: number = 0;
  timerSubscription: Subscription;
  intervalId: any;
  clientId:number;
  taskCheckIn:TaskCheckInState
 //#region HRMS Plugin

 hrmsPluginSettings: HrmsPluginSettings;

 //#endregion
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private messageService: MessageService,
    private sharedService: SharedService
  ) {
    this.loadHrmsPluginConfig();
    this.sharedService.onTaskStart.subscribe((task: Task) => {
      if (task) {
        this.clientId = task.clientId;
        this.getTask();
      }
    });

    this.sharedService.onTaskStop.subscribe((id: Number) => {
      if (id) {
        this.getTask();
      }
    });
  }

  ngOnInit() {
    this.getTask();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  getTask() {
    this.apiService
      .getCurrentRunningTask(this.authService.currentUserValue.id)
      .subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.timeEntry = resp.data;
            if (this.timeEntry) {
              this.startTime = new Date(this.timeEntry.startTime);
              this.updateElapsedTime();
            } else {
              this.timeEntry = null;
              this.elapsedTime = 0;
              this.clearTimer();
            }
          } else {
            this.timeEntry = null;
            this.clearTimer();
          }
        },
        error: (error) => {
          // Handle error
          this.timeEntry = null;
          this.clearTimer();
          console.error('error:', error);
        },
      });
  }
  async loadHrmsPluginConfig() {
    this.apiService.getHrmsPluginConfig().subscribe({
      next: (data: any) => {
        if (data) {
          if (data.isActive) {
            if (data.settings) {
              const obj = JSON.parse(data.settings);
              this.hrmsPluginSettings = obj;
            }
          }
        }
      },
      error: (error) => {},
    });
  }
  stopTask() {
    this.clearTimer();
    this.apiService.stopTask(this.timeEntry).subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.sharedService.triggerTaskStop(this.timeEntry.taskId);
          this.getTask(); // Refresh the running task
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
    });
  }

  updateElapsedTime() {
    this.clearTimer();
    this.intervalId = setInterval(() => {
      if (this.startTime) {
        this.elapsedTime = Date.now() - this.startTime.getTime();
      }
    }, 1000);
  }

  clearTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
  initiateTaskStop() {

    // Check if the current user is assigned to the task
    // if (!task.assigneeIds.includes(this.currentUserId)) {
    //   return null;
    // }

    // If hrmsPluginSettings is not defined, start the task without marking attendance
    if (!this.hrmsPluginSettings) {
      return this.stopTask();
    }
    this.stopTask();
    const { allowMarkAttendance, autoMarkAttendance, allowUserChoice } =
      this.hrmsPluginSettings;
      this.taskCheckIn = new TaskCheckInState();
    // Check attendance options
    if (allowMarkAttendance) {
      var type = 'Check Out';
      if (autoMarkAttendance) {
        this.taskCheckIn.automark = true;
        this.taskCheckIn.clientId = this.clientId;
        this.sharedService.triggerTaskCheckOut(this.taskCheckIn);
        // Auto mark attendance logic can be added here if needed
        this.stopTask();
      } else if (allowUserChoice) {
        this.taskCheckIn.automark = false;
        this.taskCheckIn.clientId = this.clientId;
        this.sharedService.triggerTaskCheckOut(this.taskCheckIn);
        // this.attendanceConfirmationModal.message =
        //   "You're about to stop this task. Would you like to check out for the day?";
        // this.attendanceConfirmationModal.showModal('check-out');
        return null; // Exit after confirmation
      }
    }

    // Default to starting the task without marking attendance


    return null;
  }
 
}
