import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import {
  DialogService,
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import {
  INotificationThreshold,
  NotificationThreshold,
} from 'src/app/Models/NotificationThreshold';
import { ClientBudgetLastSetting, Clients } from 'src/app/Models/Clients';
import {
  BudgetBillingType,
  ClientCase,
  CaseService,
} from 'src/app/Models/Case';
import { Result } from 'src/app/Models/Result';
import {
  Services,
  ServiceCategories,
  ServicesList,
} from 'src/app/Models/Services';
import { ApiService } from 'src/app/services/api.service';
import { padZero, parseJSONToLowerCaseFirstChar } from 'src/app/utils/utils';

@Component({
  selector: 'app-case-setup',
  templateUrl: './case-setup.component.html',
  styleUrls: ['./case-setup.component.css'],
})
export class CaseSetupComponent {
  @Output() saveSuccess = new EventEmitter<void>();
  temporaryStateObjectForBudgetSettings: any;
  case: ClientCase = new ClientCase();
  // service: Services = new Services();
  serviceCategoryDbList: ServiceCategories[] = [];
  servicesDbList: Services[] = [];
  tempServicesList: ServicesList[] = [];
  billingType: BudgetBillingType[] = [];
  selectedBillingType: number = 0;
  isDataSaving: boolean;
  clientInfo: Clients = new Clients();
  clientLastBudeget: ClientBudgetLastSetting = new ClientBudgetLastSetting();
  selectedThreshold: number = 0;
  isChecked: boolean = false;

  thresholds: INotificationThreshold[];

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private dialogService: DialogService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.thresholds = new NotificationThreshold().GetThresholds();

    this.case = this.dialogConfig.data.case;
    if (this.case.id > 0) {
      this.selectedBillingType = this.case.billingTypeId;
      this.selectedThreshold = this.case.notificationThreshold;
    }

    this.clientInfo = this.dialogConfig.data.clientInfo;
    if (!this.case.services || this.case.services?.length === 0) {
      this.case.services = [];
      this.addService();
      this.tempServicesList = [];
    }

    this.getServicesCategory();
    this.getServices();
    this.getBillingType();
  }

  ngOnDestroy(): void {
    this.case.services = [];
  }

  ngAfterViewInit(): void {
    if (this.case.id > 0) {
      if (this.case.startDate) {
        this.case.startDate = new Date(this.case.startDate);
      }
      if (this.case.endDate) {
        this.case.endDate = new Date(this.case.endDate);
      }
    } else {
      this.case.startDate = new Date();
    }
  }

  onCategoryChange(categoryId: number, index: number) {
    // Filter services based on the selected category
    this.tempServicesList[index].servicesList = this.servicesDbList.filter(
      (service) => service.categoryId === categoryId
    );
  }

  addService() {
    if (this.case.services.length > 0) {
      this.case.services.push(new CaseService());
    } else {
      this.case.services = [];
      this.case.services.push(new CaseService());
    }
  }
  removeService(index: number) {
    if (this.case.services.length !== 1) {
      this.case.services.splice(index, 1);
    }
  }

  public validateAndSetData() {
    this.case.clientId = this.clientInfo.id;
    if (!this.case.clientId) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the client engagement name',
        life: 3000,
      });
      return false;
    }
    if (!this.case.caseName) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the client engagement name',
        life: 3000,
      });
      return false;
    }
    if (!this.case.startDate) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the start date of the engagement',
        life: 3000,
      });
      return false;
    }
    if (!this.case.endDate) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the end date of the engagement',
        life: 3000,
      });
      return false;
    }
    if (!this.case.description) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please fill the engagement description',
        life: 3000,
      });
      return false;
    }
    if (!this.case.services[0].categoryId || this.case.services.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please select atleast 1 service',
        life: 3000,
      });
      return false;
    }
    this.case.servicesJson = JSON.stringify(this.case.services);
    this.case.billingTypeId = this.selectedBillingType;
    this.case.notificationThreshold = Number(this.selectedThreshold);
    return true;
  }

  removeCaseService(index: number) {
    this.case.services.splice(index, 1);
  }
  public getServicesCategory() {
    this.apiService.getServicesCategory().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.serviceCategoryDbList = resp.data;
        } else {
          this.serviceCategoryDbList = [];
        }
      },
      error: (error) => {
        // Handle error
      },
    });
  }
  public getServices(): void {
    this.apiService.getServices().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.servicesDbList = resp.data;

          this.tempServicesList = this.servicesDbList.map(() => {
            const serviceList = new ServicesList();
            serviceList.servicesList = [...this.servicesDbList];
            return serviceList;
          });
        } else {
        }
      },
      error: (error) => {
        console.error('error:', error);
      },
      complete: () => {},
    });
  }
  public onSubmit() {
    // Your form submission logic here
    if (this.validateAndSetData()) {
      this.isDataSaving = true;
      this.apiService.addOrUpdateCase(this.case).subscribe({
        next: (resp: Result) => {

          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close({ success: true });
            //this.getCase();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: resp.message,
              life: 3000,
            });
          }
          this.isDataSaving = false;
        },
        error: (error) => {
          // Handle error
          this.isDataSaving = false;
          console.error('error:', error);
        },
      });
    }
  }
  getBillingType() {
    this.apiService.getBillingType().subscribe({
      next: (resp: Result) => {
        if (resp.status === 'success') {
          this.billingType = resp.data;
          if (this.selectedBillingType === 0) {
            this.selectedBillingType = this.billingType[0].id;
          }
        }
      },
    });
  }

  onSelectionChange(value: any) {
    this.selectedBillingType = value;
  }

  onNotificationChange(event: any) {
    const value = event.target.value;
    this.selectedThreshold = value;
  }

  onCheckboxChange(event: any) {
    this.isChecked = event.target.checked;
    if (this.isChecked) {
      // Perform action when checkbox is checked

      this.apiService.getLastBudgetSetting(this.clientInfo.id).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            if (resp.data) {
              this.temporaryStateObjectForBudgetSettings = {
                caseServices: this.case.services,
                selectedBillingType: this.selectedBillingType,
                selectedThreshold: this.selectedThreshold,
              };
              this.clientLastBudeget = resp.data;
              this.case.services = parseJSONToLowerCaseFirstChar(
                this.clientLastBudeget.caseServiceJson
              );
              this.selectedBillingType = this.clientLastBudeget.billingTypeId;
              this.selectedThreshold =
                this.clientLastBudeget.notificationThreshold;
            } else {
              this.messageService.add({
                severity: 'info',
                summary: 'Info Alert !',
                detail: 'There was no previous record found! Create new one',
                life: 3000,
              });
              this.isChecked = false;
            }
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
      // Add your action here
    } else {
      this.case.services =
        this.temporaryStateObjectForBudgetSettings?.caseServices;
      this.selectedBillingType =
        this.temporaryStateObjectForBudgetSettings?.selectedBillingType;
      this.selectedThreshold =
        this.temporaryStateObjectForBudgetSettings?.selectedThreshold;
      // Perform action when checkbox is unchecked
      // Add your action here
    }
  }

  closeCase(id: number, active: boolean) {
    debugger;
    if (active === false) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        message:
          'Are you sure you want to close this engagement. All running tasks will be stopped?',
        accept: () => {
          this.apiService.updateCaseStatus(id, active).subscribe({
            next: (resp: Result) => {
              if (resp.status === 'success') {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success',
                  detail: resp.message,
                  life: 3000,
                });
                this.dialogRef.close({ success: false });
              } else {
                this.messageService.add({
                  severity: 'warn',
                  summary: 'Warning',
                  detail: resp.message,
                  life: 3000,
                });
              }
            },
            error: (error) => {
              // Handle error
              console.error('error:', error);
            },
          });
        },
        reject: () => {},
      });
    } else {
      this.apiService.updateCaseStatus(id, active).subscribe({
        next: (resp: Result) => {
          if (resp.status === 'success') {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: resp.message,
              life: 3000,
            });
            this.dialogRef.close({ success: false });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: resp.message,
              life: 3000,
            });
          }
        },
        error: (error) => {
          // Handle error
          console.error('error:', error);
        },
      });
    }
  }

  closeModal(): void {
    this.dialogRef.close({ success: false });
  }

  padZero(id: number, minLength: number) {
    return '#' + padZero(id, minLength);
  }
}
