<div class="modal-body">
  <div class="row">
    <div class="col-md-7">
      <h4 class="mt-2" style="font-size: 14px !important;padding: 3px 5px; display: flex;">
        <strong class="badge rounded-pill bg-secondary mr-1" style="height: 16px;">{{padZero(task.id,3)}} </strong>
        {{task.title}}
      </h4>
      <div style="background: #f4f9ff; padding: 5px 5px;">
        <div class="row align-items-center mb-2 mt-1">
          <div class="col-md-4" style="align-self: self-start;">
            <span class="badge pill" style="background-color: #539090 !important;">
              Created At: {{ task.dateCreated| date:'medium'}}
            </span>
          </div>
          <div class="col-md-8 text-right pr-3 d-flex align-items-center justify-content-end">
            <button class="btn action-button-outline" [title]="task.isStarted ? 'Stop Task' : 'Start Task'"
              (click)="task.isStarted ? initiateTaskStop(task)  : initiateTaskStart(task)"
              [disabled]="task.taskStatusId === 3 || !task.assigneeIds?.includes(currentUserId) || task.caseStatus === false">
              <i [className]=" task.isStarted ? 'fa-solid fa-stop text-danger'
              : 'fa-solid fa-play text-info'"
                [ngClass]=" { 'text-danger' : task.isStarted && task.taskStatusId
              !==3, 'text-info' : !task.isStarted && task.taskStatusId !==3, 'text-gray' : task.taskStatusId===3 }"></i>
              {{ task.isStarted ? 'Stop' : 'Start' }}
            </button>
            <span *ngIf="task.isStarted" class="ml-2"><app-task-timer [showTimeOnly]="true"></app-task-timer></span>

          </div>
        </div>

        <div class="d-flex align-items-center mt-3" *ngIf="selectedAssignees">
          <div class="btn-group">
            <button #taskDropdownButton type="button" class="btn btn-sm form-action-menu-btn"
              (click)="assignees.toggle($event)">
              <i class="fa-light fa-user-group mr-1"></i> {{ "Task Assignees" | translate }} {{selectedAssignees &&
              selectedAssignees.length > 0 ?
              '(' + selectedAssignees.length + ')' : ''}}
            </button>
            <p-overlayPanel #assignees appendTo="body">
              <div style="width: 750px; padding: 5px 10px 5px 10px;">
                <div class="d-flex align-content-center justify-content-between">
                  <h5 class="mb-0" style="margin-bottom: 0px; font-size: 18px;">Assignees</h5>
                </div>


                <div *ngIf="selectedAssignees && selectedAssignees.length > 0; else noAssignee"
                  class="table-responsive table-assignees-container" style="max-height: 200px;">
                  <table class="table table-hover table-assignees">
                    <thead>
                      <tr class="sticky-header">
                        <th class="text-center" style="width: 35px;">-</th>
                        <th style="width: 280px;">Assignee</th>
                        <th style="width: 115px;">Total Hours</th>
                        <th style="width: 115px;">Cost (Hour)</th>
                        <th style="width: 120px;">Total Est. Cost</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let item of selectedAssignees">
                        <tr>
                          <td>
                            <p-checkbox [(ngModel)]="item.selected" [binary]="true" [value]="item.selected" />
                          </td>
                          <td>
                            <div class="d-flex align-items-center" style="font-size: 0.875rem;">
                              <img [src]="item.profilePictureUrl"
                                style="width: 18px; height: 18px; border-radius: 50%; margin-right: 7px;">
                              <span>{{item.name}}</span>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center me-3">

                              <span class="me-1">{{item.estimatedHours}}</span>
                              <span class="me-1">H</span>


                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <span class="me-1">Rs. </span>
                              <span class="me-1">{{item.ratesPerHour}}</span>
                              <!-- <input type="text" class="form-control" [(ngModel)]="item.ratesPerHour" placeholder="0"
                                [disabled]="billableTypeId !== 2" style="width: 70px;" /> -->
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center justify-content-center">
                              <span class="me-1">{{(item.ratesPerHour * item.estimatedHours) | currency: 'Rs '}}</span>
                            </div>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>

                <ng-template #noAssignee>
                  <div class="d-flex flex-column justify-content-center align-items-center">
                    <i class="fa-light fa-user-group mt-3 mb-2"></i>No one assigned yet
                  </div>
                </ng-template>


              </div>
            </p-overlayPanel>

            <ng-container *ngIf="selectedAssignees.length > 0">
              <div class="likers-group ms-1">
                <img *ngFor="let u of selectedAssignees | slice:0:3; let i=index" [src]="u.profilePictureUrl"
                  alt="{{u.name}}" title="{{u.name}}">
              </div>
            </ng-container>
          </div>


          <div style="display: flex;align-items: center;width: 300px;margin-left: 1rem;">
            <label for="status" class="mr-1 fw-600">Status:</label>
            <p-dropdown [options]="taskStatuses" appendTo="body" name="status" optionLabel="status" [filter]="true"
              [(ngModel)]="task.taskStatusId" filterBy="status" [showClear]="false" dataKey="id" optionValue="id"
              placeholder="Select Status" (onChange)="updateTaskStatus(task)">
            </p-dropdown>
          </div>
        </div>

        <div class="row align-items-center mt-4">
          <div class="col-md-8 d-flex align-items-center">
            <span class="badge" style="background: #ffffff;color: #1b1b1b;font-size: 11px;font-weight: 500;">Service:
              {{task.serviceName}} | {{task.caseName}} </span>
          </div>
          <div class="col-md-4 text-right">
            <ng-container *ngFor="let tag of selectedTags">
              <span class="badge rounded-pill bg-secondary mr-1">#{{tag.tagName}}</span>
            </ng-container>
          </div>
        </div>


      </div>

      <div class="mt-3">
        <div class="d-flex align-items-center mb-2">
          <i class="fa-regular fa-circle-info fs-16px mr-1"></i>
          <h5 class="m-0">Description</h5>
        </div>

        <div class="NgxEditor__Wrapper ngx-editor-task-detail">
          <ngx-editor [editor]="editorDetails" [(ngModel)]="jsonDescriptionDoc" [disabled]="true"
            [placeholder]="'Task details'"></ngx-editor>
        </div>
      </div>

      <div class="mt-5">
        <div class="d-flex align-items-center mb-2">
          <i class="fa-regular fa-badge-check fs-16px mr-1"></i>
          <h5 class="m-0">Acceptance Criteria</h5>
        </div>

        <div class="NgxEditor__Wrapper ngx-editor-task-accept">
          <ngx-editor [editor]="editorAcceptance" [(ngModel)]="jsonAcceptanceDoc" [disabled]="true"
            [placeholder]="'Acceptance criteria'"></ngx-editor>
        </div>
      </div>

      <ng-container *ngIf="task.checklists && task.checklists.length > 0">
        <div class="card mt-3">
          <div class="card-header">
            <i class="fa-solid fa-list-check"></i> Task Dependencies
          </div>
          <div class="card-body">
            <div class="flex flex-column">
              <div *ngFor="let chk of task.checklists" class="field-checkbox">
                <p-checkbox [(ngModel)]="chk.isCompleted" [binary]="true" [label]="chk.description" name="group"
                  [value]="chk.isCompleted" class="mb-2" />
              </div>
              <div class="card-footer mt-2" style="display: flex;justify-content: flex-end;">
                <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;">Cancel</button>
                <button type="button" class="border-0 btn btn-primary btn-sm action-button"
                  (click)="saveCheckList()">Update Dependencies</button>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="mt-4" style="background: #ff00000f;padding: 1rem 0.25rem 0.25rem 1rem;">
          <div class="d-flex align-items-center mb-3">
            <i class="fa-solid fa-list-check fs-16px mr-1"></i>
            <h5 class="m-0">Task Dependencies</h5>
          </div>

          <div class="flex flex-column ml-3 gap-2">
            <div *ngFor="let chk of task.checklists" class="field-checkbox">
              <p-checkbox [(ngModel)]="chk.isCompleted" [binary]="true" [label]="chk.description" name="group"
                [value]="chk.isCompleted" class="mb-2" />
            </div>
            <div style="padding: 5px 10px;display: flex;justify-content: flex-end;border-top: 1px solid #e9e9e9;">
              <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;">Cancel</button>
              <button type="button" class="border-0 btn btn-primary btn-sm action-button" style="width: 80px;"
                (click)="saveCheckList()">Update</button>
            </div>
          </div>
        </div> -->
      </ng-container>

      <div class="mt-5">
        <div class="d-flex align-items-center mb-2">
          <i class="fa-regular fa-comment fs-16px mr-1"></i>
          <h5 class="m-0">Comments ({{comments.length}})</h5>
        </div>

        <ng-container *ngIf="isConversationStarted">
          <div class="NgxEditor__Wrapper ngx-editor-task-comment">
            <ngx-editor [editor]="editorComment" [(ngModel)]="jsonDoc" [disabled]="false"

              [placeholder]="'Enter your comment here'"></ngx-editor>
            <ngx-editor-menu *ngIf="editorCommentMenu" [editor]="editorComment" [toolbar]="toolbar"></ngx-editor-menu>
            <div
              style="padding: 5px 10px;display: flex;justify-content: flex-end;background: #fdfdfd;border-top: 1px solid #e9e9e9;">
              <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;" (click)="toggleConversation()">Cancel</button>
              <button type="button" class="border-0 btn btn-primary btn-sm action-button" style="width: 130px;"
                (click)="saveComment()">Save Comment</button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isConversationStarted">
          <div class="no-comments-card">
            <i class="fa-regular fa-comment-dots no-comments-image"></i>
            <h5>No Comments Yet</h5>
            <p class="no-comments-text">Be the first to comment on this discussion and start the conversation!</p>
            <button class="btn action-button-outline" (click)="toggleConversation()">Start the Conversation</button>
          </div>
        </ng-container>

        <p-listbox [options]="comments" [style]="{ width: '100%' }">
          <ng-template let-comment pTemplate="item">
            <div>
              <h5><img [src]="comment.commenterPicture" class="comment-user-pic" /> {{comment.commenterName}}</h5>
              <div class="badge pill bg-indigo">
                <div class="text-white"> {{comment.dateCreated |date:'medium' }} </div>
              </div>
              <div [innerHTML]="comment.commentText | safeHtml" class=""></div>
              <!-- <div class="d-flex align-items-center" style="display:none">
                <button class="btn comment-btn-link"><i _nz-icon="" nztype="like" class="anticon anticon-like"><svg
                      viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em"
                      data-icon="like" aria-hidden="true">
                      <path
                        d="M885.9 533.7c16.8-22.2 26.1-49.4 26.1-77.7 0-44.9-25.1-87.4-65.5-111.1a67.67 67.67 0 00-34.3-9.3H572.4l6-122.9c1.4-29.7-9.1-57.9-29.5-79.4A106.62 106.62 0 00471 99.9c-52 0-98 35-111.8 85.1l-85.9 311H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h601.3c9.2 0 18.2-1.8 26.5-5.4 47.6-20.3 78.3-66.8 78.3-118.4 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7 0-12.6-1.8-25-5.4-37 16.8-22.2 26.1-49.4 26.1-77.7-.2-12.6-2-25.1-5.6-37.1zM184 852V568h81v284h-81zm636.4-353l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 16.5-7.2 32.2-19.6 43l-21.9 19 13.9 25.4a56.2 56.2 0 016.9 27.3c0 22.4-13.2 42.6-33.6 51.8H329V564.8l99.5-360.5a44.1 44.1 0 0142.2-32.3c7.6 0 15.1 2.2 21.1 6.7 9.9 7.4 15.2 18.6 14.6 30.5l-9.6 198.4h314.4C829 418.5 840 436.9 840 456c0 16.5-7.2 32.1-19.6 43z">
                      </path>
                    </svg></i><span class=""> 44 </span></button>
                <button class="btn comment-btn-link"><i _nz-icon="" nztype="dislike"
                    class="anticon anticon-dislike"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor"
                      width="1em" height="1em" data-icon="dislike" aria-hidden="true">
                      <path
                        d="M885.9 490.3c3.6-12 5.4-24.4 5.4-37 0-28.3-9.3-55.5-26.1-77.7 3.6-12 5.4-24.4 5.4-37 0-28.3-9.3-55.5-26.1-77.7 3.6-12 5.4-24.4 5.4-37 0-51.6-30.7-98.1-78.3-118.4a66.1 66.1 0 00-26.5-5.4H144c-17.7 0-32 14.3-32 32v364c0 17.7 14.3 32 32 32h129.3l85.8 310.8C372.9 889 418.9 924 470.9 924c29.7 0 57.4-11.8 77.9-33.4 20.5-21.5 31-49.7 29.5-79.4l-6-122.9h239.9c12.1 0 23.9-3.2 34.3-9.3 40.4-23.5 65.5-66.1 65.5-111 0-28.3-9.3-55.5-26.1-77.7zM184 456V172h81v284h-81zm627.2 160.4H496.8l9.6 198.4c.6 11.9-4.7 23.1-14.6 30.5-6.1 4.5-13.6 6.8-21.1 6.7a44.28 44.28 0 01-42.2-32.3L329 459.2V172h415.4a56.85 56.85 0 0133.6 51.8c0 9.7-2.3 18.9-6.9 27.3l-13.9 25.4 21.9 19a56.76 56.76 0 0119.6 43c0 9.7-2.3 18.9-6.9 27.3l-13.9 25.4 21.9 19a56.76 56.76 0 0119.6 43c0 9.7-2.3 18.9-6.9 27.3l-14 25.5 21.9 19a56.76 56.76 0 0119.6 43c0 19.1-11 37.5-28.8 48.4z">
                      </path>
                    </svg></i><span class=""> 17 </span></button>
                <a href="/treehole-angular-firebase/post/Cs9aBrJ2reiiC9sIFO0a" class=""><i _nz-icon="" nztype="comment"
                    class="anticon anticon-comment"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor"
                      width="1em" height="1em" data-icon="comment" aria-hidden="true">
                      <defs>
                        <style></style>
                      </defs>
                      <path
                        d="M573 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40zm-280 0c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z">
                      </path>
                      <path
                        d="M894 345a343.92 343.92 0 00-189-130v.1c-17.1-19-36.4-36.5-58-52.1-163.7-119-393.5-82.7-513 81-96.3 133-92.2 311.9 6 439l.8 132.6c0 3.2.5 6.4 1.5 9.4a31.95 31.95 0 0040.1 20.9L309 806c33.5 11.9 68.1 18.7 102.5 20.6l-.5.4c89.1 64.9 205.9 84.4 313 49l127.1 41.4c3.2 1 6.5 1.6 9.9 1.6 17.7 0 32-14.3 32-32V753c88.1-119.6 90.4-284.9 1-408zM323 735l-12-5-99 31-1-104-8-9c-84.6-103.2-90.2-251.9-11-361 96.4-132.2 281.2-161.4 413-66 132.2 96.1 161.5 280.6 66 412-80.1 109.9-223.5 150.5-348 102zm505-17l-8 10 1 104-98-33-12 5c-56 20.8-115.7 22.5-171 7l-.2-.1A367.31 367.31 0 00729 676c76.4-105.3 88.8-237.6 44.4-350.4l.6.4c23 16.5 44.1 37.1 62 62 72.6 99.6 68.5 235.2-8 330z">
                      </path>
                      <path d="M433 421c-23.1 0-41 17.9-41 40s17.9 40 41 40c21.1 0 39-17.9 39-40s-17.9-40-39-40z">
                      </path>
                    </svg></i> 31 </a>
              </div> -->
            </div>
          </ng-template>
        </p-listbox>

      </div>

    </div>

    <div class="col-md-5 right-panel">

      <p-tabView>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center text-nowrap gap-2">
              <i class="fa-regular fa-clock-rotate-left"></i>
              <span class="font-bold white-space-nowrap m-0">
                History
              </span>
            </div>
          </ng-template>
          <table style="width: 100%;">
            <tr *ngFor="let item of taskActivities">
              <div class="activity-container">
                <img role="button" class="mr-1 user-image" [src]="item.profilePictureUrl" [alt]="item.userName"
                  [title]="item.userName">
                <span><strong>{{item.userName?item.userName:'John Doe'}}&nbsp;</strong> {{item.activityDescription}}
                </span>
                <time>{{item.dateCreated|date:'medium'}}</time>
              </div>
            </tr>

          </table>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center text-nowrap gap-2">
              <i class="fa-regular fa-timer"></i>
              <span class="font-bold white-space-nowrap m-0">
                Time Tacking
              </span>
            </div>
          </ng-template>

          <div class="not-started-card" *ngIf="!timeEntries || timeEntries.length === 0">
            <i class="fa-regular fa-timer not-started-image"></i>
            <h3>No Time Logged</h3>
            <p>It looks like no one has started the task yet. Please check back later for updates.</p>
          </div>

          <ng-container *ngIf="timeEntries && timeEntries?.length>0">
            <div class="total-time">
              Total Time: {{totalHours.split(':')[0]}} hours {{totalHours.split(':')[1]}} minutes
            </div>
            <table class="table table-sm time-tracking-table">
              <thead>
                <tr>
                  <th>USER</th>
                  <th>START</th>
                  <th>END</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of timeEntries">
                  <td>
                    <div class="user-info">
                      <img
                        [src]="item.profilePictureUrl?item.profilePictureUrl:'https://cdn.pixabay.com/photo/2020/07/01/12/58/icon-5359553_640.png'"
                        [alt]="item.userName" class="user-image">
                      <span class="username">{{item.userName}}</span>
                    </div>
                  </td>
                  <td>{{item.startTime ? (item.startTime | date:'short') : '-'}}</td>
                  <td>{{ item.endTime ? (item.endTime|date:'short'):'-'}}</td>
                  <td>
                    {{ (item.startTime?item.startTime:null) | timeDifference:(item.endTime?item.endTime:null) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <div class="flex align-items-center text-nowrap gap-2">
              <i class="fa-regular fa-paperclip"></i>
              <span class="font-bold white-space-nowrap m-0">
                Attachments
              </span>
            </div>
          </ng-template>
          <div>
            <div class="card mt-3">
              <div class="card-header">
                <div class="col-md-12 text-right pr-3 d-flex align-items-center justify-content-end">
                  <button class="border-0 btn btn-info btn-sm action-button ml-5 mb-2" (click)="triggerFileUpload()">
                    Upload Files
                  </button>
                </div>
                <span *ngIf="isUploading">Please wait......</span>
                <p-progressBar *ngIf="progress > 0" [value]="progress" [style]="{ height: '12px' }"></p-progressBar>
                <!-- Hidden file upload component -->
                <p-fileUpload #fileUploadInput 
                  [style.display]="'none'"
                  name="demo[]" 
                  url="https://www.primefaces.org/cdn/api/upload.php"
                  
                  (onRemove)="onRemove($event)"  
                  (onSelect)="onSelect($event)" 
                  [auto]="true" 
                  [multiple]="true" 
                  [maxFileSize]="1000000">
                </p-fileUpload>
              </div>
              <div class="card-body">
                <div class="flex flex-column">
                  <div *ngIf="uploadedFiles.length > 0 || task.documents.length > 0 ; else noFilesFound" class="card-body">
                    <div class="table-responsive activity-tables"
                    style="max-height: 380px; ">
                      <table class="table table-hover">
                        <thead class="sticky-header thead-light">
                          <tr>
                            <th style="width: 200px;">{{ "Name" | translate }}</th>
                            <th>{{ "Size" | translate }}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let item of task.documents; let rowIndex = index">
                            <tr>
                              <td class="leadname-td">
                                <div class="d-flex align-items-center leadname-container">
                                  <div class="leadname d-flex align-items-center file-name">
                                    <div class="file-icon mr-2" [ngClass]="item.fileExt"></div>{{ item.documentName }}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div class="ellipsis" style="max-width: 130px; font-weight: 500;">
                                  {{ item.size }} Kb
                                </div>
                              </td>
                              <td><button type="button" class=" btn btn-sm btn-danger delete-btn ms-2" (click)="onRemoveFile(item)">
                                <i class="fa fa-light fa-minus" style="font-size: 9px;"></i>
                              </button>
                              <a [href]="item.fileLocation"  class=" btn btn-sm btn-primary view-btn ms-2">
                              
                                <i class="fa fa-light fa-eye" style="font-size: 9px;"></i>
                            </a></td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ng-template #noFilesFound>
                    <div class="flex align-items-center" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    flex-direction: column;">
                    <span>
                      <i class="fa-thin fa-paperclip fs-64px mb-3"></i>
                    </span>
                    <span class="font-bold white-space-nowrap m-0">No attachment found</span>
                    </div>
                  </ng-template>
                </div>
        
      
                  <div class="card-footer mt-2" style="display: flex;justify-content: flex-end;" *ngIf="isDocUploading">
                    <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"(click)="removeDocs()">Cancel</button>
                    <button type="button" class="border-0 btn btn-primary btn-sm action-button"
                      (click)="saveTaskDocs()">Update Task Documents</button>
                  </div>
                </div>
              </div>
            
            
                <!-- <div style="width: 750px; padding: 5px 10px 5px 10px;">       
                  <div class="form-group col-md-12">
                  
                     <div class="col-md-8 text-right pr-3 d-flex align-items-center justify-content-end">
                    <button class="border-0 btn btn-info btn-sm action-button ml-5 mb-2" (click)="triggerFileUpload()">
                      Upload Files
                    </button>
                  </div>
                    <p-progressBar *ngIf="progress > 0" [value]="progress" [style]="{ height: '12px' }"></p-progressBar>
             
                    <p-fileUpload #fileUploadInput 
                      [style.display]="'none'"
                      name="demo[]" 
                      url="https://www.primefaces.org/cdn/api/upload.php"
                      
                      (onRemove)="onRemove($event)"  
                      (onSelect)="onSelect($event)" 
                      [auto]="true" 
                      [multiple]="true" 
                      [maxFileSize]="1000000">
                    </p-fileUpload>
                  
  
                    <div *ngIf="uploadedFiles.length > 0 || task.documents.length > 0 ; else noFilesFound" class="card-body">
                      <div class="table-responsive activity-tables"
                      style="max-height: 180px; width: 586px;">
                        <table class="table table-hover">
                          <thead class="sticky-header thead-light">
                            <tr>
                              <th style="width: 200px;">{{ "Name" | translate }}</th>
                              <th>{{ "Size" | translate }}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container *ngFor="let item of task.documents; let rowIndex = index">
                              <tr>
                                <td class="leadname-td">
                                  <div class="d-flex align-items-center leadname-container">
                                    <div class="leadname d-flex align-items-center file-name">
                                      <div class="file-icon mr-2" [ngClass]="item.fileExt"></div>{{ item.documentName }}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div class="ellipsis" style="max-width: 130px; font-weight: 500;">
                                    {{ item.size }} Kb
                                  </div>
                                </td>
                                <td><button type="button" class=" btn btn-sm btn-danger delete-btn ms-2" (click)="onRemoveFile(item)">
                                  <i class="fa fa-light fa-minus" style="font-size: 9px;"></i>
                                </button>
                                <a [href]="item.fileLocation"  class=" btn btn-sm btn-primary view-btn ms-2">
                                
                                  <i class="fa fa-light fa-eye" style="font-size: 9px;"></i>
                              </a></td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <ng-template #noFilesFound>
                      <div class="flex align-items-center" style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      height: 150px;
                      flex-direction: column;">
                      <span>
                        <i class="fa-thin fa-paperclip fs-64px mb-3"></i>
                      </span>
                      <span class="font-bold white-space-nowrap m-0">No attachment found</span>
                      </div>
                    </ng-template>
                  </div>
                  </div> -->
        
              
            <!-- <p-fileUpload name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php"
              (onUpload)="onUpload($event)" (onSelect)="onSelect($event)" [multiple]="true" [maxFileSize]="1000000">
              <ng-template pTemplate="content">
                <ng-container *ngIf="uploadedFiles.length === 0">
                  <div class="flex align-items-center" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 150px;
                    flex-direction: column;">
                    <span>
                      <i class="fa-thin fa-paperclip fs-64px mb-3"></i>
                    </span>
                    <span class="font-bold white-space-nowrap m-0">No attachment found</span>
                  </div>
                </ng-container>
              </ng-template>
            </p-fileUpload> -->
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>

<div class="modal-footer pr-4">
  <button type="button" class="btn btn-sm action-button-cancel mr-2" style="width: 80px;"
    (click)="closeModal()">Cancel</button>
  <button type="button" class="border-0 btn btn-primary btn-sm action-button" (click)="onSubmit()"
    style="width: 80px;" style="display: none;">Update</button>
</div>


